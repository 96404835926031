angular.module('boyds').controller('searchFiltersController', searchFiltersController);

searchFiltersController.$inject = ['domService', 'service', '$scope'];
function searchFiltersController(domService, service, $scope) {
    var vm = this;
    vm.makes = [];
    vm.models = [];
    vm.shapes = [];
    vm.actions = [];

    vm.isLoadingMakes = false;
    vm.isLoadingModels = false;
    vm.isLoadingShapes = false;
    vm.isLoadingActions = false;

    vm.isModelsDisabled = true;
    vm.isActionsDisabled = true;
    vm.isShapesDisabled = true;

    vm.focusMakes = false;
    vm.focusModels = false;
    vm.focusActions = false;
    vm.focusShapes = false;

    vm.selectedMake = null;
    vm.selectedModel = null;
    vm.selectedAction = null;
    vm.selectedShape = null;

    vm.getMakes = getMakes;
    vm.selectMake = selectMake;
    vm.selectModel = selectModel;
    vm.selectAction = selectAction;
    vm.selectShape = selectShape;
    vm.unfocused = unfocused;
    vm.resetProductFilters = resetProductFilters;
    vm.clearFiltersButton = clearFiltersButton;
    vm.onSearchTermChange = onSearchTermChange;

    $scope.$watch('vm.searchTerm', onSearchTermChange);

    init();

    return vm;

    function init() {
        clearFilters();
        getMakes()
            .then(function () { if (vm.preselectFilter) setFocus(); });
    }

    function onSearchTermChange(newVal, oldVal) {
        if (newVal !== oldVal)
            resetProductFilters();
    }

    function selectMake(make) {
        vm.selectedMake = make;
        if (!make || make.Id === undefined) return;
        vm.models = vm.actions = vm.shapes = [];
        return getModels()
            .then(function () { vm.callback(getCallbackObject()) })
            .then(setFocus);
    }

    function selectModel(model) {
        vm.selectedModel = model;
        if (!model || model.Id === undefined) return;
        return getActions()
            .then(function () { vm.callback(getCallbackObject()) })
            .then(setFocus);
    }

    function selectAction(action) {
        vm.selectedAction = action;
        if (!action || action.Id === undefined) return;
        return getShapes()
            .then(function () { vm.callback(getCallbackObject()) })
            .then(setFocus);
    }

    function selectShape(shape) {
        vm.selectedShape = shape;
        if (!shape || shape.Id === undefined) return;
        vm.callback(getCallbackObject());
        setFocus();
    }

    function getMakes() {
        vm.isLoadingMakes = true;
        if (vm.searchTerm)
            return getMakesBySearchTerm();
        return getAllMakes();
    }

    function getAllMakes() {
        return service.getMakes().then(function (response) {
            vm.makes = response.data;
            vm.isLoadingMakes = false;
        });
    }

    function getMakesBySearchTerm() {
        return service.searchGetMakes(vm.searchTerm).then(function (response) {
            vm.makes = response.data;
            vm.isLoadingMakes = false;
        });
    }

    function getModels() {
        vm.isLoadingModels = true;
        if (vm.searchTerm)
            return getModelsBySearchTerm();
        return getAllModels();
    }

    function getAllModels() {
        return service.getModels(vm.selectedMake.Id).then(function (response) {
            vm.models = response.data;
            setupModelsDropdown();
            vm.isLoadingModels = false;
        });
    }

    function getModelsBySearchTerm(id) {
        return service.searchGetModels(vm.searchTerm, vm.selectedMake.Id).then(function (response) {
            vm.models = response.data;
            setupModelsDropdown();
            vm.isLoadingModels = false;
        });
    }

    function getActions() {
        vm.isLoadingActions = true;
        if (vm.searchTerm)
            return getActionsBySearchTerm();
        return getAllActions();
    }

    function getAllActions() {
        return service.getActions(vm.selectedModel.Id).then(function (response) {
            vm.actions = response.data;
            setupActionsDropdown();
            vm.isLoadingActions = false;
        });
    }

    function getActionsBySearchTerm() {
        return service.searchGetActions(vm.searchTerm, vm.selectedMake.Id, vm.selectedModel.Id).then(function (response) {
            vm.actions = response.data;
            setupActionsDropdown();
            vm.isLoadingActions = false;
        });
    }

    function getShapes() {
        vm.isLoadingShapes = true;
        if (vm.searchTerm)
            return getShapesBySearchTerm();
        return getAllShapes();
    }

    function getAllShapes() {
        return service.getShapes(vm.selectedAction.Id).then(function (response) {
            vm.shapes = response.data;
            setupShapesDropdown();
            vm.isLoadingShapes = false;
        });
    }

    function getShapesBySearchTerm() {
        return service.searchGetShapes(vm.searchTerm, vm.selectedMake.Id, vm.selectedModel.Id, vm.selectedAction.Id).then(function (response) {
            vm.shapes = response.data;
            setupShapesDropdown();
            vm.isLoadingShapes = false;
        });
    }

    function setupMakesDropdown() {
        vm.selectedMake = null;
        vm.selectedModel = null;
        vm.selectedAction = null;
        vm.selectedShape = null;
        vm.isModelsDisabled = true;
        vm.isActionsDisabled = true;
        vm.isShapesDisabled = true;
    }

    function setupModelsDropdown() {
        vm.selectedModel = null;
        vm.selectedAction = null;
        vm.selectedShape = null;
        vm.isActionsDisabled = true;
        vm.isShapesDisabled = true;
        vm.isModelsDisabled = false;
    }

    function setupActionsDropdown() {
        vm.shapes = [];
        vm.selectedAction = null;
        vm.selectedShape = null;
        vm.isActionsDisabled = false;
        vm.isShapesDisabled = true;
    }

    function setupShapesDropdown() {
        vm.selectedShape = null;
        vm.isShapesDisabled = false;
    }

    function clearFilters() {
        clearAllSelections();
        clearDropdownOptions();
        resetDropdownsToDisabled();
    }

    function clearFiltersButton() {
        resetProductFilters();
        vm.callback(getCallbackObject());
    }

    function resetProductFilters() {
        clearFilters();
        return getMakes().then(function () { if (vm.preselectFilter) setFocus(); });
    }

    function clearAllSelections() {
        vm.selectedMake = null;
        vm.selectedModel = null;
        vm.selectedAction = null;
        vm.selectedShape = null;
    }

    function clearDropdownOptions() {
        vm.makes = [];
        vm.models = [];
        vm.shapes = [];
        vm.actions = [];
    }

    function resetDropdownsToDisabled() {
        vm.isModelsDisabled = true;
        vm.isActionsDisabled = true;
        vm.isShapesDisabled = true;
    }

    function setFocus() {
        resetFocus();
        if (vm.selectedMake === null) vm.focusMakes = true;
        else if (vm.selectedModel === null) vm.focusModels = true;
        else if (vm.selectedAction === null) vm.focusActions = true;
        else if (vm.selectedShape === null) vm.focusShapes = true;
    }

    function resetFocus() {
        vm.focusMakes = false;
        vm.focusModels = false;
        vm.focusActions = false;
        vm.focusShapes = false;
    }

    function unfocused(event) {
        domService.attr(event.target, 'size', 0);
        domService.removeClass(event.target, 'floating-select');
    }

    function getCallbackObject() {
        return {
            selectedMake: vm.selectedMake,
            selectedModel: vm.selectedModel,
            selectedAction: vm.selectedAction,
            selectedShape: vm.selectedShape
        };
    }
}