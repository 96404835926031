angular.module("boyds").controller('searchCartController', searchCartController);

searchCartController.$inject = ['$rootScope', 'config', 'service'];
function searchCartController($rootScope, config, service) {
    var vm = this;
    $rootScope.cartCount = 0;
    
    init();

    return vm;

    function init() {
        updateCartCount();
        $rootScope.$on(config.events.itemAddedToCart, updateCartCount);
    }
    
    function updateCartCount() {
        service.getCartCount().then(function (response) {
            if (typeof (response.data) != "number") response.data = 0;
            $rootScope.cartCount = response.data;
        });
    }
};