angular.module("boyds").factory('service', ['$http', 'config', function ($http, config) {
    return {
        getRecommendedProducts: function (page, pageSize) {
            return $http({
                url: config.service.getRecommendedProducts.url + "?page=" + page + "&pageSize=" + pageSize,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getRecommendedProductsCount: function () {
            return $http({
                url: config.service.getRecommendedProductsCount.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        addRecommendedProductToCart: function (productUrl) {
            return $http({
                url: config.service.addRecommendedProductToCart.url + insertValue(productUrl),
                method: 'POST'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getCartOrderTotal: function () {
            return $http({
                url: config.service.getCartOrderTotal.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getOrderSubTotal: function () {
            return $http({
                url: config.service.getOrderSubTotal.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getDealerSubTotal: function () {
            return $http({
                url: config.service.getDealerSubTotal.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getCheckoutStepOne: function () {
            return $http({
                url: config.service.getCheckoutStepOne.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getCheckoutStepShipping: function () {
            return $http({
                url: config.service.getCheckoutStepShipping.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getCheckoutStepPaymentAndReview: function () {
            return $http({
                url: config.service.getCheckoutStepPaymentAndReview.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getElementsInitializers: function () {
            return $http({
                url: config.service.getElementsInitializers.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getCheckoutStepReceipt: function (orderId) {
            return $http({
                url: config.service.getCheckoutStepReceipt.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getCartOrderForCurrentCustomer: function (unit) {
            return $http({
                url: config.service.getCartOrderForCurrentCustomer.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getCountries: function () {
            return new $http.get(config.service.getCountries.url);
        },
        getMakes: function () {
            return new $http.get(config.service.getMakes.url);
        },
        getModels: function (makeId) {
            return getByParentId(config.service.getModels.url, makeId);
        },
        getActions: function (modelId) {
            return getByParentId(config.service.getActions.url, modelId);
        },
        getShapes: function (actionId) {
            return getByParentId(config.service.getShapes.url, actionId);
        },
        getProduct: function (shapeId) {
            return getByParentId(config.service.getProduct.url, shapeId);
        },
        getGunstockClassificationIds: function (urlName) {
            return new $http.get(config.service.getGunstockClassificationIds.url.replace('{urlName}', urlName));
        },
        getTotalResultsCount: function (searchTerm, make, model, action, shape) {
            return new $http.get(config.service.getTotalResultsCount.url + searchTerm
                + insertValue(make) + insertValue(model) + insertValue(action) + insertValue(shape)
            );
        },
        getAllProductsCount: function (make, model, action, shape) {
            return new $http.get(config.service.getAllProductsCount.url
                + insertValue(make) + insertValue(model) + insertValue(action) + insertValue(shape));
        },
        search: function (searchTerm, page, take, make, model, action, shape) {
            return new $http.get(config.service.search.url + searchTerm + insertValue(page) + insertValue(take)
                + insertValue(make) + insertValue(model) + insertValue(action) + insertValue(shape));
        },
        searchSuggestions: function (searchTerm) {
            return new $http.get(config.service.searchSuggestions.url + searchTerm);
        },
        searchForOrders: function (searchTerm, page, take) {
            return new $http.get(config.service.searchForOrders.url + "?searchTerm=" + searchTerm + "&skipPage=" + page + "&takeCount=" + take);
        },
        searchForOrdersCount: function (searchTerm) {
            return new $http.get(config.service.searchForOrdersCount.url + "?searchTerm=" + searchTerm);
        },
        sendPasswordRecoveryEmail: function (email) {
            return new $http.post(config.service.sendPasswordRecoveryEmail.url + email);
        },
        searchGetMakes: function (searchTerm) {
            return new $http.get(config.service.searchGetMakes.url + searchTerm);
        },
        searchGetModels: function (searchTerm, makeId) {
            return new $http.get(config.service.searchGetModels.url + searchTerm + insertValueSimple(makeId));
        },
        searchGetActions: function (searchTerm, makeId, modelId) {
            return new $http.get(config.service.searchGetActions.url + searchTerm + insertValueSimple(makeId) + insertValueSimple(modelId));
        },
        searchGetShapes: function (searchTerm, makeId, modelId, actionId) {
            return new $http.get(config.service.searchGetShapes.url + searchTerm + insertValueSimple(makeId) + insertValueSimple(modelId) + insertValueSimple(actionId));
        },
        getAllProducts: function (page, take, make, model, action, shape) {
            return new $http.get(config.service.getAllProducts.url + insertValue(page) + insertValue(take)
                + insertValue(make) + insertValue(model) + insertValue(action) + insertValue(shape));
        },
        addRecommendedProductToCart: function (productUrl) {
            return new $http.post(config.service.addRecommendedProductToCart.url + '/' + productUrl);
        },
        addProductToCart: function (product) {
            return new $http.post(config.service.addProductToCart.url, product);
        },
        addProductsToCart: function (product) {
            return new $http.post(config.service.addProductsToCart.url, product);
        },
        addCustomizedGunstockToCart: function (products) {
            return new $http.post(config.service.addCustomizedGunstockToCart.url, products);
        },
        registerCustomer: function (customer) {
            return new $http.post(config.service.registerCustomer.url, customer);
        },
        getCustomerProfile: function (userId) {
            return new $http.get(config.service.getCustomerProfile.url + "?userId=" + userId);
        },
        updateCustomerProfile: function (customer) {
            return new $http.put(config.service.updateCustomerProfile.url, customer);
        },
        getAvailableAddOns: function (productId) {
            return getByProductId(config.service.getAvailableAddOns.url, productId);
        },
        getCartCount: function () {
            return new $http.get(config.service.getCartCount.url);
        },
        updateCartAddress: function (address) {
            return $http({
                url: config.service.updateCartAddress.url,
                method: 'PUT',
                data: address
            })
        },
        updateCartAddresses: function (address) {
            return $http({
                url: config.service.updateCartAddresses.url,
                method: 'POST',
                data: address,
                contentType: "application/json"
            })
        },
        updateCartOrderMetaFields: function (metaFields) {
            return $http({
                url: config.service.updateCartOrderMetaFields.url,
                method: 'POST',
                data: metaFields
            })
        },
        updateCartOrderShippingMethod: function (shippingMethod) {
            return $http({
                url: config.service.updateCartOrderShippingMethod.url,
                method: 'PUT',
                data: shippingMethod
            })
        },
        updateCheckoutStepOne: function (checkoutData) {
            return $http({
                url: config.service.updateCheckoutStepOne.url,
                method: 'PUT',
                data: checkoutData
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        createUserAccount: function (newUser) {
            return $http({
                url: config.service.createUserAccount.url,
                method: 'POST',
                data: newUser
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        processOrder: function (paymentProcessorResponse) {
            return $http({
                url: config.service.processOrder.url,
                method: 'POST',
                contentType: "application/json",
                data: paymentProcessorResponse
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        placeOrder: function (paymentResponse) {
            return $http({
                url: config.service.placeOrder.url,
                method: 'POST',
                contentType: "application/json",
                data: paymentResponse
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getOrderDetails: function (orderId) {
            return new $http.get(config.service.getOrderDetails.url + orderId);
        },
        getOrder: function (orderId) {
            return new $http.get(config.service.getOrder.url + orderId);
        },
        submitPromotionForm: function (formValues) {
            //https://github.com/Sitefinity/feather-widgets/blob/master/Telerik.Sitefinity.Frontend.Forms/Mvc/Controllers/FormController.cs
            //http://www.uncorkedstudios.com/blog/multipartformdata-file-upload-with-angularjs/
            //https://developer.mozilla.org/en-US/docs/Web/API/FormData

            formValues.FormId = config.service.submitPromotionForm.formId;

            var formData = new FormData();
            for (var key in formValues)
                formData.append(key, formValues[key]);

            return $http({
                method: 'POST',
                url: config.service.submitPromotionForm.url,
                data: formData,
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },
        getWorkstations: function () {
            return $http({
                url: config.service.getWorkstations.url,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        getWorkstationInstructions: function (workstationNumber, purchaseOrderId) {
            return $http({
                url: `${config.service.getWorkstationInstructions.url}?workstationGuid=${workstationNumber}&purchaseOrderId=${purchaseOrderId}`,
                method: 'GET'
            }).then(
                function successCallback(response) {
                    if (response.status === 200)
                        return response.data;
                },
                function failureCallback(response) {
                    throw response.data.ExceptionMessage;
                }
            );
        },
        importParts: function (workstationIds) {
            return $http({
                url: `${config.service.importParts.url}?workstationIds=${JSON.stringify(workstationIds)}`,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.status === 200)
                    return response.data;
            });
        },
        requestHelp: function (workstation, workstationId, purchaseOrderNumber, helpType, additionalInfo) {
            let data = {
                workstation: workstation,
                workstationId: workstationId,
                purchaseOrderNumber: purchaseOrderNumber,
                helpType: helpType,
                additionalInfo: additionalInfo
            };
            return $http({
                url: config.service.requestHelp.url,
                method: 'POST',
                data
            }).then(
                function successCallback(response) {
                if (response.status === 200)
                    return response.data;
                },
                function failureCallback(response) {
                    throw response.data.ExceptionMessage;
                }
            );
        },
        voidTransaction: function (transactionId) {
            return $http({
                url: config.service.voidTransaction.url + transactionId,
                method: 'POST'
            }).then(
                function successCallback(response) {
                    if (response.status === 200)
                        return true;
                },
                function failureCallback(response) {
                    throw response.data.ExceptionMessage;
                }
            );
        }
    }

    function getByParentId(url, parentId) {
        return new $http.get(url, { params: { parentId: parentId } });
    }

    function getByProductId(url, productId) {
        return new $http.get(url, { params: { productId: productId } });
    }

    function getByFilters(url, make, model, action, shape) {
        return new $http.get(url, {
            params: {
                makeTitle: make,
                modelTitle: model,
                actionTitle: action,
                shapeTitle: shape
            }
        });
    }

    function insertValue(value) {
        return value ? "/" + encodeURIComponent(value) : "";
    }

    function insertValueSimple(value) {
        return value ? "/" + value : "";
    }

    function insertOptional(value) {
        return value ? value : null;
    }
}]);