angular.module("boyds").controller('checkoutController', checkoutController);

checkoutController.$inject = ['$scope', '$rootScope', '$element', 'service', '$q', 'config', '$timeout'];
function checkoutController($scope, $rootScope, $element, service, $q, config, $timeout) {
    var nextStep = $($element).find("#next-step").data("next-step-url");
    var previousStep = $($element).find("#previous-step").data("next-step-url");

    const StatusID = {
        101: 'Approved',
        102: 'AuthOnly',
        111: 'Refunded',
        121: 'AVSOnly',
        131: 'PendingOrigination',
        132: 'Originating',
        133: 'Originated',
        134: 'Settled',
        201: 'Voided',
        301: 'Declined',
        331: 'ChargedBack'
    }

    $("#previous-step").click(function () {
        $("#previous-step").html('<i class="fa fa-cog fa-spin fa-fw"></i> Processing');
    });

    $scope.order = {};
    $scope.newUser = {};
    $scope.payment = {};
    $scope.cartOrder = {};
    $scope.addressesDto = {};
    $scope.checkoutData = {};
    $scope.billingAddress = {
        Country: "US"
    };
    $scope.shippingAddress = {
        Country: "US"
    };
    $scope.orderMetaFields = {};
    $scope.shippingMethodChosen = {};
    $scope.isInternationalOrder = isInternationalOrder;

    var promises = [];
    $scope.errors = [];
    $scope.products = [];
    $scope.discounts = [];
    $scope.Countries = [];
    $scope.CreditCardExpirationYearOptions = [];
    $scope.CreditCardExpirationMonthOptions = [];

    $scope.total = 0;
    $scope.taxTotalPrice = 0;
    $scope.productTotalPrice = 0;
    $scope.checkoutStepOneValidateCount = 0;

    $scope.userId = null;
    $scope.taxDisplay = '';
    $scope.nextPageUrl = '';
    $scope.PromotionEmail = '';
    $scope.PromotionOptIn = true;
    $scope.paymentIsVisible = false;
    $scope.sagePaymentIsSuccessful = false;
    $scope.verifiedHuman = false;
    $rootScope.DisplayPageLoadingSpinner = false;

    $scope.customOrderText = config.checkout.customOrderText;
    $scope.States = config.states;
    $scope.displayErrors = displayErrors;
    $scope.phone_number = config.inputPatterns.phone;
    $scope.emailFormat = config.inputPatterns.email;
    $scope.ShippingDifferent = localStorage.ShippingDifferent;
    $scope.RegisterNewAccount = localStorage.RegisterNewAccount;

    $scope.InitializeCheckout = function (checkoutStep) {
        promises = [];
        $scope.errors = [];
        switch (checkoutStep) {
            case "StepOne":
                $scope.loadCheckoutStepOne();
                break;
            case "Shipping":
                $scope.loadShippingStep();
                break;
            case "PaymentAndReview":
                $scope.loadPaymentAndReviewStep();
                break;
            case "Receipt":
                $scope.loadCheckoutStepReceipt();
                break;
        }

        $q.all(promises).then(function success(data) {
            $scope.getTotal();
            $scope.recalculateTotal();
            $("#checkout").fadeIn("slow");
            $("#loading-message").hide();
        }, function fail(err) {
            console.log(err);
            $("#loading-message").hide();
        });
    }

    $scope.loadCheckoutStepOne = function () {
        promises.push(service.getCheckoutStepOne().then(function successCallback(response) {
            $scope.cartOrder = response.cartOrder;
            $scope.Countries = response.countries;
            $scope.orderMetaFields = response.metaFields;

            setTaxDisplay();
            initializeCheckoutAddress();
            setProducts($scope.cartOrder);
            setDiscounts($scope.cartOrder);
            setProductTotals($scope.cartOrder);

            setTimeout(function () {
                $("#checkout .billing-state").val($scope.billingAddress.StateRegion);
                $("#checkout .shipping-state").val($scope.shippingAddress.StateRegion);

                $("#billing-address.checkout-geoaddress").checkoutAutoAddress();
                $("#shipping-address.checkout-geoaddress").checkoutAutoAddress();
                $scope.$apply();
            }, 100);
        }));
    }
    $scope.loadShippingStep = function () {
        showLoadingSpinner();
        promises.push(service.getCheckoutStepShipping().then(function successCallback(response) {
            hideLoadingSpinner();
            $scope.cartOrder = response.cartOrder;
            $scope.shippingMethods = response.shippingOptions;
            $scope.shippingTaxRate = response.shippingTaxRate;

            setTaxDisplay();
            getAddresses($scope.cartOrder);
            setProducts($scope.cartOrder);
            setDiscounts($scope.cartOrder);
            setProductTotals($scope.cartOrder);

            if ($scope.shippingMethods.length == 0) {
                $scope.errors.push("Unable to ship to provided shipping address.");
                displayErrors();
                $scope.hasShippingOptions = false;
            }

            setShippingMethod(response);
        }));
    };

    $scope.loadPaymentAndReviewStep = function () {
        $scope.shippingMethods = JSON.parse(localStorage.shippingMethods);
        setExpirationYears();
        setExpirationMonths();
        promises.push(service.getCheckoutStepPaymentAndReview().then(function successCallback(response) {
            $scope.cartOrder = response.cartOrder;
            $scope.orderHasCustomProducts = false;
            $scope.Countries = response.countries;
            $scope.shippingTaxRate = response.shippingTaxRate;
            $scope.creditCardsAccepted = response.creditCardsAccepted;

            setTaxDisplay();
            setDiscounts($scope.cartOrder);
            getAddresses($scope.cartOrder);
            setProducts($scope.cartOrder);
            setProductTotals($scope.cartOrder);

            if ($scope.cartOrder.ShippingServiceCode != null && $scope.cartOrder.ShippingServiceCode != "")
                restoreShippingMethodChosen();
        }));
    }
    $scope.loadCheckoutStepReceipt = function () {
        promises = [];
        $scope.errors = [];

        promises.push(service.getCheckoutStepReceipt().then(function successCallback(response) {
            $scope.order = response.order;
            $scope.payment = response.order.Payments[0];
            $scope.orderMetaFields = response.metaFields;

            getAddresses($scope.order);
            setProducts($scope.order);
            setDiscounts($scope.order);
            setProductTotals($scope.order);
        }));

        $q.all(promises).then(function success(data) {
            localStorage.setItem('ShippingDifferent', "false");
            $("#checkout").show();
            $("#loading-message").hide();
        }, function fail(err) {
            console.log(err)
            console.log('loadCheckoutStepReceipt FAIL');
        });
    }
    $scope.loadCheckoutStepPrint = function (checkoutStep) {
        promises = [];
        $scope.errors = [];
        promises.push(service.getCheckoutStepReceipt().then(function successCallback(response) {
            $scope.order = response.order;
            $scope.payment = response.order.Payments[0];
            $scope.orderMetaFields = response.metaFields;

            getAddresses($scope.order);
            setProducts($scope.order);
            setDiscounts($scope.order);
            setProductTotals($scope.order);
        }));

        $q.all(promises).then(function success(data) {
            $scope.getTotal();
            $("#checkout").show();
            $("#loading-message").hide();
            setTimeout(function () {
                window.print();
            }, 300);

        }, function fail(err) {
            console.log(err);
            console.log('loadCheckoutStepPrint FAIL');
        });
    }

    $scope.saveCheckoutStepOne = function () {
        showLoadingSpinner();
        promises = [];
        var valid = false;
        $scope.nextStepHtml = $("#next-step").html();

        valid = validateCheckoutStepOne();

        if (valid) {
            $scope.checkoutData.addresses = combineAddressObjects($scope.billingAddress, $scope.shippingAddress);

            promises.push(promiseToSubmitPromotionFormIfOptedIn());
            promises.push(service.updateCartAddresses($scope.checkoutData.addresses).then(function (response) { }));
            $q.all(promises).then(function success(data) {
                // if success, then move on to the next step in the checkout process
                moveToNextCheckoutStep();
            }, function fail(err) {
                //errorHandling(err.ExceptionMessage, false);
                if (err.data)
                    $scope.errors.push(err.data.ExceptionMessage);
                else $scope.errors.push(err);
                $(".checkout-errors").show();
                displayErrors();
            });
        }
        else {
            displayErrors();
        }
    }
    $scope.saveCheckoutStepShipping = function () {
        if ($scope.errors.length > 0)
            return;
        showLoadingSpinner();
        promises = [];
        var valid = false;

        valid = validateCheckoutStepTwo();

        if (valid) {
            promises.push(service.updateCartOrderShippingMethod($scope.shippingMethodChosen).then(function (response) {
            }));
            $q.all(promises).then(function success(data) {
                // if success, then move on to the next step in the checkout process
                moveToNextCheckoutStep();
            }, function fail(err) {
                console.log(err);
                console.log('saveCheckoutStepShipping FAIL');
            });
        }
        else {
            displayErrors();
        }
    }
    $scope.validateCheckoutStepPayment = function () {
        showLoadingSpinner();
        if (paymentStepValid()) {
            displayErrors();
            $("#checkout-errors").hide();

            $timeout(function () {
                if (false) {
                    //if ($scope.RegisterNewAccount) {
                    $scope.newUser.cartOrderId = $scope.cartOrder.Id;
                    $scope.newUser.registerUser = $scope.RegisterNewAccount;
                    promises.push(service.createUserAccount($scope.newUser).then(function successCallback(response) {
                        loadReviewStep();
                        $("#checkout-errors").hide();
                        hideLoadingSpinner();
                    }, function () {
                        displayErrors();
                        $("#next-step").html('REVIEW & CONFIRM ORDER <i class="fa fa-long-arrow-right" aria-hidden="true"></i>');
                    }
                    ));
                }
                else {
                    loadReviewStep();
                    hideLoadingSpinner();
                }
            }, 500);
        }
        else {
            displayErrors();
            hideLoadingSpinner();
        }
    }
    $scope.makePayment = function () {
        showLoadingSpinner();
        $("#checkout-errors").hide();
        $scope.payment.CardHolderName = $scope.billingAddress.FirstName + " " + $scope.billingAddress.LastName;
        loadPaymentStep();
    }
    $scope.processOrder = function (paymentProcessorResponse) {
        showLoadingSpinner();
        nextStep = location.href.replace("payment", "receipt");
        if ($scope.payment.CardHolderName == null || $scope.payment.CardHolderName == "")
            $scope.payment.CardHolderName = $scope.billingAddress.FirstName + " " + $scope.billingAddress.LastName;
        return service.processOrder(paymentProcessorResponse).then(function success(response) {
            $scope.paymentResponse = response;
            if ($scope.paymentResponse.IsSuccess)
                moveToNextCheckoutStep();
            else if ($scope.paymentResponse.GatewayAdditionalResult == "252" || $scope.paymentResponse.GatewayAdditionalResult == "253")
                moveToNextCheckoutStep();
            else if ($scope.paymentResponse == 'undefined' || $scope.paymentResponse.length < 2) {
                $("#place-order").html($scope.nextStepHtml);
                displayPaymentResponseErrorMessage($scope.paymentResponse);
            }
            else {
                $("#place-order").html($scope.nextStepHtml);
                displayPaymentResponseErrorMessage($scope.paymentResponse);
            }
            hideLoadingSpinner();
        }, function fail(err) {
            updatePaymentScreenOnError();
            displayErrorMessageFromServer(err.data.ExceptionMessage);
            hideLoadingSpinner();
        });
    }
    $scope.voidTransaction = function (transactionId, errorMessage) {
        showLoadingSpinner();
        return service.voidTransaction(transactionId).then(result => {
            $scope.elementsError = errorMessage;
            hideLoadingSpinner();
        })
    }
    $scope.updateShippingAddressState = function () {

        if ($("#ShippingDifferent").attr("checked") == "checked") {
            localStorage.setItem('ShippingDifferent', "true");
            $("#ShippingDifferent").prop("checked", true);
            setTimeout(function () {
                $("#shipping-address.checkout-geoaddress").checkoutAutoAddress();
            }, 100);
        }
        else {
            localStorage.setItem('ShippingDifferent', "false");
            $("#ShippingDifferent").prop("checked", false);
        }
    };
    $scope.getShippingAddressState = function () {
        if ($scope.ShippingDifferent == "true") {
            $("#ShippingDifferent").prop("checked", true);
            $scope.ShippingDifferent = true;
        }
        else {
            $("#ShippingDifferent").prop("checked", false);
            $scope.ShippingDifferent = false;
        }
    }
    $scope.getAgreeTerms = function () {
        if ($scope.AgreeTerms == "true") {
            $("#AgreeTerms").prop("checked", true);
            $scope.AgreeTerms = true;
        }
        else {
            $("#AgreeTerms").prop("checked", false);
            $scope.AgreeTerms = false;
        }
    }
    $scope.shippingMethodSelected = function ($event) {
        $scope.shippingMethodChosen.ServiceCode = $event.target.dataset.serviceCode;
        $scope.shippingMethodChosen.ServiceName = $event.target.dataset.serviceName;
        $scope.shippingMethodChosen.ServicePrice = $event.target.dataset.servicePrice;
        $scope.shippingMethodChosen.ShippingCarrierName = $event.target.dataset.carrierName;
        $scope.shippingMethodChosen.ShippingMethodId = $event.target.dataset.shippingMethodId;
        $scope.shippingMethodChosen.ShippingServiceName = $event.target.dataset.shippingServiceName;
        $scope.recalculateTotal();
    };
    $scope.registerNewAccountCheckboxChanged = function ($event) {
        if ($("#RegisterUser").attr("checked") == "checked") {
            localStorage.setItem('RegisterNewAccount', "true");
            $("#RegisterUser").prop("checked", true);
            $scope.RegisterNewAccount = true;
            $scope.newUser.userEmail = $scope.billingAddress.Email;
        }
        else {
            localStorage.setItem('RegisterNewAccount', "false");
            $("#RegisterUser").prop("checked", false);
            $scope.RegisterNewAccount = false;
        }
    }
    $scope.toggleReviewPaymentViews = function (viewType) {
        if (viewType == "review") {
            $("#loading-message").show();
            $("#checkout").hide();
            $scope.paymentIsVisible = false;

            setTimeout(function () {
                $("#checkout-review").show();
                $("#checkout").hide().fadeIn("slow");
                $("#loading-message").hide();
            }, 500);
        }
        else if (viewType == "back") {
            $("#loading-message").show();
            $("#checkout").hide();
            location.reload();

            setTimeout(function () {
                $scope.paymentIsVisible = true;
                $("#checkout").hide().fadeIn("slow");
                $("#loading-message").hide();
            }, 500);
        }
        else if (viewType == "confirm") { }
    }
    $scope.getTotal = function () {
        var total = 0.00;
        if ($scope.cartOrder.Total)
            total = parseFloat($scope.cartOrder.Total);
        $scope.total = total.toFixed(2);
    }
    $scope.getTax = function () {
        return "$" + $scope.cartOrder.ExchangeRateTax;
    }
    $scope.getBaseTotal = function () {
        var baseTotal = 0;
        $scope.products.forEach(function (item) { baseTotal += item.BasePrice; });
        return baseTotal;
    }
    $scope.recalculateTotal = function () {
        var shippingPrice = parseFloat($scope.shippingMethodChosen.ServicePrice);
        var total = $scope.cartOrder.SubTotalDisplay;

        if ($scope.cartOrder.ExchangeRateSubTotal)
            total = parseFloat($scope.cartOrder.ExchangeRateSubTotal);

        if ($scope.cartOrder.ExchangeRateDiscountTotal && $scope.cartOrder.Discounts.length != 0) {
            total -= $scope.cartOrder.ExchangeRateDiscountTotal;
        } else if ($scope.cartOrder.DiscountTotal) {
            total -= $scope.cartOrder.DiscountTotal;
        }

        if (shippingPrice)
            total += shippingPrice;
      
        if ($scope.cartOrder.ExchangeRateTax) {
            $scope.taxDisplay = $scope.cartOrder.ExchangeRateTax.toFixed(2);
            total += $scope.cartOrder.ExchangeRateTax;
        }
        $scope.total = total.toFixed(2);
    }
    $scope.isCardNumberValid = function () {
        switch ($scope.payment.CardType) {
            case "visa":
                $scope.regex = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
                $scope.currentTypeName = "Visa";
                break;
            case "delta":
                $scope.regex = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
                $scope.currentTypeName = "Delta";
                break;
            case "uke":
                $scope.regex = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
                $scope.currentTypeName = "Uke";
                break;
            case "dinersclub":
                $scope.regex = new RegExp("^3(?:0[0-5]|[68][0-9])[0-9]{11}$");
                $scope.currentTypeName = "Diners Club";
                break;
            case "laser":
                $scope.regex = new RegExp("^(6304|6706|6709|6771)[0-9]{12,15}$");
                $scope.currentTypeName = "Laser";
                break;
            case "americanexpress":
                $scope.regex = new RegExp("^3[47][0-9]{13}$");
                $scope.currentTypeName = "American Express";
                break;
            case "mastercard":
                $scope.regex = new RegExp("^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]");
                $scope.currentTypeName = "Master Card";
                break;
            case "discover":
                $scope.regex = new RegExp("^6(?:011|5[0-9]{2})[0-9]{3,}$");
                $scope.currentTypeName = "Discover";
                break;
            default:
                $scope.currentTypeName = "Card";
                break;

        }
        if ($scope.currentTypeName != "Card")
            if (!$scope.regex.test($scope.payment.Number))
                return false;
        var credit_card_number = $scope.payment.Number.replace(/-/g, '');
        if (!passesLuhnAlgorithm(credit_card_number))
            return false;
        return true;
    };
    $scope.changePromoOptIn = function () {
        if ($scope.PromotionOptIn && !$scope.PromotionEmail)
            $scope.PromotionEmail = $scope.billingAddress.Email;
    }
    $scope.onRecaptchaSuccess = function () {
        $scope.verifiedHuman = true;
        $scope.initializeElements();
    }
    $scope.getElementsInitializers = function () {
        return service.getElementsInitializers().then(elementsInitializers => {
            if (elementsInitializers.ElementsClientToken == "") {
                $scope.elementsError = "There was an issue checking out. Please try refreshing the page.";
            } else if (elementsInitializers.ElementsClientToken == "rl") {
                $scope.elementsErrorCustom = "If you are having trouble placing an order, please contact our customer service hotline at 605-996-5011 for assistance.";
            }
            $scope.elementsInitializers = elementsInitializers;
        })
    }
    $scope.initializeElements = function () {
        if (!$scope.verifiedHuman)
            return;
        try {
            let { FirstName, LastName, Email, Phone, Address, Country, StateRegion, City, PostalCode } = $scope.billingAddress;
            let Name = FirstName + ' ' + LastName;
            if(StateRegion == null){
                StateRegion = '';
            }
            const addressMaxLength = 32; // Set by Fortis
            const cityMaxLength = 36; // Set by Fortis
            const stateMaxLength = 2; // Set by Fortis, though it is unclear if the limit is 2 or 24.
            const postalCodeMaxLength = 10; // Set by Fortis
            const countryMaxLength = 10; // Set by Fortis, though we use 2-character country codes.
            const phoneMaxLength = 10; // Set by Fortis
            const emailMaxLength = 128; // Set by Fortis (assumed limit based on custom field limitation)
            const nameMaxLength = 128; // Set by Fortis (custom field limitation)
            Address = Address.length > addressMaxLength ? Address.substring(0, addressMaxLength) : Address;
            City = City.length > cityMaxLength ? City.substring(0, cityMaxLength) : City;
            StateRegion = StateRegion.length > stateMaxLength ? StateRegion.substring(0, stateMaxLength) : StateRegion;
            PostalCode = PostalCode.length > postalCodeMaxLength ? PostalCode.substring(0, postalCodeMaxLength) : PostalCode;
            Country = Country.length > countryMaxLength ? Country.substring(0, countryMaxLength) : Country;
            Phone = Phone.length > phoneMaxLength ? Phone.substring(0, phoneMaxLength) : Phone;
            Email = Email.length > emailMaxLength ? Email.substring(0, emailMaxLength) : Email;
            Name = Name.length > nameMaxLength ? Name.substring(0, nameMaxLength) : Name;
            $scope.getElementsInitializers().then(() => {
                const { ElementsClientToken, TransactionApiId, Environment } = $scope.elementsInitializers;
                var elements = new Commerce.elements(ElementsClientToken);
                elements.create({
                    container: '#payment',
                    theme: 'default',
                    environment: Environment,
                    showReceipt: false,
                    appearance: {
                        colorButtonActionBackground: '#245079',
                        colorButtonActionText: '#ffffff',
                        colorButtonSelectedBackground: '#24507980',
                        borderRadius: '4px'
                    },
                    fields: {
                        billing: [
                            { name: 'address', required: true, readOnly: true, value: Address },
                            { name: 'city', required: false, readOnly: true, value: City },
                            { name: 'state', required: Country === 'US', readOnly: true, value: StateRegion || '' },
                            { name: 'postal_code', required: true, readOnly: true, value: PostalCode },
                            { name: 'country', required: true, readOnly: true, value: Country }
                        ],
                        additional: [
                            { name: 'email_address', hidden: true, value: Email },
                            { name: 'transaction_api_id', hidden: true, value: TransactionApiId },
                            { name: 'transaction_custom_1', hidden: true, value: Name }
                        ]
                    },
                });
                elements.on('done', async (result) => {
                    if (result.data.status_code == Object.keys(StatusID)[Object.values(StatusID).findIndex(x => 'Approved')]) {
                        if (await transactionAmountHasBeenChanged(result.data.transaction_amount)) {
                            $scope.voidTransaction(result.data.id, 'Your order total did not match the payment total. Your transaction has been voided.').then(() => {
                                resetPaymentProcessing();
                            });
                            return;
                        }
                        $scope.processOrder(result.data);
                    } else {
                        $scope.elementsError = 'An error occurred while trying to process your payment with the following status code: ' +
                            StatusID[result.data.status_code] + '.';
                        $scope.$apply();
                        resetPaymentProcessing();
                    }
                });
            });
            $scope.elementsError = '';
        }
        catch (e) {
            $scope.elementsError = "There was an issue loading the payment processor. Please return to the cart and try again.";
        }
    }

    var unbindPaymentSuccessWatch = $scope.$watch(function (scope) {
        if (scope.sagePaymentIsSuccessful) {
            unbindPaymentSuccessWatch();
            scope.placeOrder();
        }
    }, true);

    enableCheckoutAutoAddress();

    return $scope;

    function resetPaymentProcessing() {
        $('.payment').empty();
        $scope.verifiedHuman = false;
    }

    async function transactionAmountHasBeenChanged(transactionAmount) {
        return transactionAmount !== Math.round(await service.getCartOrderTotal() * 100); // Convert to pennies.
    }

    function initializeCheckoutAddress() {
        if ($scope.cartOrder.Addresses.length > 0)
            for (var i = 0; i < $scope.cartOrder.Addresses.length; i++) {
                if ($scope.cartOrder.Addresses[i].AddressType == 1)
                    $scope.billingAddress = $scope.cartOrder.Addresses[i];
                else if ($scope.cartOrder.Addresses[i].AddressType == 2)
                    $scope.shippingAddress = $scope.cartOrder.Addresses[i];
            }
        else if ($scope.userId != config.defaults.guid)
            service.getCustomerProfile($scope.userId).then(function (response) {
                $scope.billingAddress = {
                    FirstName: response.data.FirstName,
                    LastName: response.data.LastName,
                    Company: response.data.Company,
                    Email: response.data.EmailAddress,
                    Phone: response.data.PhoneNumber,
                    Address: response.data.StreetAddress,
                    Address2: response.data.StreetAddress2,
                    Country: response.data.CountryCode,
                    StateRegion: response.data.StateCode,
                    City: response.data.City,
                    PostalCode: response.data.PostalCode
                };
            });
    }

    function getAddresses(order) {
        for (var i = 0; i < order.Addresses.length; i++)
            if (order.Addresses[i].AddressType == 1)
                $scope.billingAddress = order.Addresses[i];
            else if (order.Addresses[i].AddressType == 2)
                $scope.shippingAddress = order.Addresses[i];
    }

    function setTaxDisplay() {
        if ($scope.cartOrder.ExchangeRateTax == null)
            $scope.taxDisplay = "Tax will be calculated on the next step"
        else
            $scope.taxDisplay = $scope.cartOrder.ExchangeRateTax.toFixed(2)
    }

    function setProducts(order) {
        $scope.products = [];
        for (var i = 0; i < order.Details.length; i++) {
            $scope.products.push(order.Details[i]);
            if (order.Details[i] !== null && order.Details[i].Sku !== null && order.Details[i].Sku.endsWith("ZZ"))
                $scope.orderHasCustomProducts = true;
        }
    }

    function setProductTotals(cartOrder) {
        cartOrder.Details.forEach(function (el) {
            el.Total = el.Price * el.Quantity;
        });
    }

    function setDiscounts(order) {
        $scope.discounts = [];
        for (var i = 0; i < order.Discounts.length; i++)
            $scope.discounts.push(order.Discounts[i]);
    }

    function setShippingMethod(response) {
        localStorage.setItem('shippingMethods', JSON.stringify($scope.shippingMethods));

        if ($scope.cartOrder.ShippingServiceCode != null && $scope.cartOrder.ShippingServiceCode != "")
            restoreShippingMethodChosen();
        else {
            $scope.shippingMethodChosen.ServiceCode = response.shippingOptions[0].ServiceCode;
            $scope.shippingMethodChosen.ServiceName = response.shippingOptions[0].ServiceName;
            $scope.shippingMethodChosen.ServicePrice = response.shippingOptions[0].ServicePrice;
            $scope.shippingMethodChosen.ShippingCarrierName = response.shippingOptions[0].ShippingCarrierName;
            $scope.shippingMethodChosen.ShippingMethodId = response.shippingOptions[0].ShippingMethodId;
            $scope.shippingMethodChosen.ShippingServiceName = response.shippingOptions[0].ShippingServiceName;
            setTimeout(function () {
                if ($scope.shippingMethodChosen.ServiceName && $scope.shippingMethodChosen.ServiceName.length > 0)
                    $('[data-service-code=' + $scope.shippingMethodChosen.ServiceCode + ']').prop("checked", true);
            }, 200);
            return;
        }
    }

    function updatePaymentScreenOnError() {
        promises.push(service.getCheckoutStepPaymentAndReview().then(function (response) {
            $scope.cartOrder = response.cartOrder;
            setTaxDisplay();
            setProducts($scope.cartOrder);
            setDiscounts($scope.cartOrder);
            setProductTotals($scope.cartOrder);
            $scope.recalculateTotal();
        }));
    }

    function promiseToSubmitPromotionFormIfOptedIn() {
        if ($scope.PromotionOptIn && promotionEmailIsValid())
            return service.submitPromotionForm(getPromotionFormValues())
            ['catch'](function () { });
    }

    function promotionEmailIsValid() {
        var isValid = typeof ($scope.billingAddress.Email) === 'string'
            && $scope.billingAddress.Email.match(config.inputPatterns.email);

        return isValid;
    }

    function getPromotionFormValues() {
        var values = {},
            conf = config.service.submitPromotionForm;

        values[conf.emailFieldName] = $scope.billingAddress.Email;
        values[conf.optInFieldName] = $scope.PromotionOptIn ? conf.yesValue : conf.noValue;

        return values;
    }

    function passesLuhnAlgorithm(identifier) {
        var sum = 0,
            alt = false,
            i = identifier.length - 1,
            num;

        if (identifier.length < 13 || identifier.length > 19)
            return false;

        while (i >= 0) {
            num = parseInt(identifier.charAt(i), 10);   //get the next digit

            //if it's not a valid number, abort
            if (isNaN(num))
                return false;

            //if it's an alternate number...
            if (alt) {
                num *= 2;
                if (num > 9)
                    num = (num % 10) + 1;
            }

            alt = !alt;     //flip the alternate bit
            sum += num;     //add to the rest of the sum
            i--;            //go to next digit
        }

        //determine if it's valid
        return (sum % 10 == 0);
    }

    function setExpirationMonths() {
        currentMonth = new Date().getMonth();
        for (i = 1; i <= 12; i++) {
            if (i < 10) sm = "0" + i;
            else sm = i;
            $scope.CreditCardExpirationMonthOptions.push({ numericMonth: i, stringMonth: sm.toString() });
        }
    }

    function setExpirationYears() {
        currentYear = new Date().getFullYear();
        for (i = currentYear; i < currentYear + 10; i++)
            $scope.CreditCardExpirationYearOptions.push({ fullYear: i, shortYear: i.toString().slice(-2) });
    }

    function paymentStepValid() {
        $scope.errors = [];
        return $scope.sagePaymentIsSuccessful;
    }

    function displayPaymentResponseErrorMessage(paymentResponse) {
        $scope.errors = [];
        $scope.errors.push(paymentResponse.GatewayResponse);
        displayErrors();
    }

    function validateCheckoutStepOne() {
        $scope.buttonIsEnabled = true;
        if ($scope.shippingAddress.Country != 'US')
            $("#checkout #shipping-address .state").removeClass("ng-invalid").addClass('ng-valid');

        if ($scope.billingAddress.Country != 'US')
            $("#checkout #billing-address .state").removeClass("ng-invalid").addClass('ng-valid');

        var invalidFormElements = $(".ng-invalid");
        var shippingOptions = $(".shipping-option");
        $scope.errors = [];

        if (invalidFormElements.length > 0) {
            invalidFormElements.addClass("ng-dirty");
            $scope.errors.push("- Please fill out all the required fields below.");
        }

        if (!isZipCodeAndCountryCodeValid($scope.billingAddress.Country, $scope.billingAddress.PostalCode))
            $scope.errors.push("- The billing zip code entered is not a valid zip code for the country selected.");

        if ($scope.ShippingDifferent)
            if (!isZipCodeAndCountryCodeValid($scope.shippingAddress.Country, $scope.shippingAddress.PostalCode))
                $scope.errors.push("- The shipping zip code entered is not a valid zip code for the country selected.");

        if ($scope.errors.length > 0) {
            scrollTo("#checkout", 75);
            $("#checkout-errors").show();
            return false;
        }
        else
            $("#checkout-errors").hide();

        return true;
    }

    function showLoadingSpinner() {
        $rootScope.DisplayPageLoadingSpinner = true;
        try { $rootScope.$apply(); } catch (e) { }
    }

    function hideLoadingSpinner() {
        $rootScope.DisplayPageLoadingSpinner = false;
        try { $rootScope.$apply(); } catch (e) { }
    }

    function isZipCodeAndCountryCodeValid(countryCode, zipCode) {
        switch (countryCode) {
            /* Numeric codes */
            case "IS":
                return (/^[0-9]{3}$/gi).test(zipCode);
            case "AU":
            case "AT":
            case "BE":
            case "BG":
            case "DK":
            case "SV":
            case "HU":
            case "LU":
            case "NZ":
            case "NO":
            case "PH":
            case "ZA":
            case "CH":
                return (/^[0-9]{4}$/gi).test(zipCode);
            case "AR":
                return (/^([0-9]{4})|([a-z][0-9]{4}[a-z]{3})$/gi).test(zipCode);
            case "US":
            case "HR":
            case "FI":
            case "FR":
            case "DE":
            case "IT":
            case "TH":
            case "UA":
                return (/^[0-9]{5}$/gi).test(zipCode);
            case "CZ":
            case "GR":
            case "SE":
            case "SK":
                return (/^[0-9]{3}\ [0-9]{2}$/gi).test(zipCode);
            case "PL":
                return (/^[0-9]{2}\-[0-9]{3}$/gi).test(zipCode);
            case "BR":
                return (/^[0-9]{5}(\-[0-9]{3})?$/gi).test(zipCode);
            case "NC":
                return (/^988[0-9]{2}$/gi).test(zipCode);
            case "ES":
                return (/^(0[1-9]|[1-4][0-9]|5[0-2])[0-9]{3}$/gi).test(zipCode);
            case "KZ":
                return (/^[0-9]{6}$/gi).test(zipCode);
            case "GB":
                return (/^((?![qvx])[a-z])((?![ijz])[a-z])?[0-9]((?![iloq])[a-y0-9])?\ [0-9]((?![cikmov])[a-z]){2}$/gi).test(zipCode);
            case "CA":
                return (/^(?![dfioqu])[a-z][0-9](?![dfioqu])[a-z]\ [0-9](?![dfioqu])[a-z][0-9]$/gi).test(zipCode);
            case "NL":
                return (/^([0-9]{4}\ [a-z]{2})?$/gi).test(zipCode);
            case "IE":
                return (/^[A-Za-z]\d{2}\s[A-Za-z\d]{4}$/gi).test(zipCode);
            case "MT":
                return (/^[a-z]{3}\ [0-9]{4}$/gi).test(zipCode);
            case "BW":
                return true;
            default:
                return false;
        }
    }

    function validateCheckoutStepTwo() {
        var shippingOptions = $(".shipping-option");
        $scope.errors = [];
        if (shippingOptions.prop("required")) {
            $(".shipping").addClass("shipping-invalid");
            $scope.errors.push("Please select a shipping method.");
        }
        if ($scope.errors.length > 0) {
            scrollTo("#checkout", 75);
            $("#checkout-errors").show();
            return false;
        }
        else
            $("#checkout-errors").hide();
        return true;
    }

    function delayedCloseWindow() {
        $("#countdown-message").show();
        var seconds_left = 10;
        var interval = setInterval(function () {
            $("#countdown").html(--seconds_left);

            if (seconds_left <= 0) {
                window.close();
            }
        }, 1000);
    }
    function displayErrors() {
        scrollTo("#checkout", 75);
        if ($scope.errors.length > 0) {
            $("#checkout-errors ul").html("");

            for (var i = 0; i < $scope.errors.length; i++)
                $("#checkout-errors ul").append("<li>" + $scope.errors[i] + "</li>")

            $("#checkout-errors").show();
        }
        else {
            $("#checkout-errors").hide();
            $("#checkout-errors ul").html("");
        }
        hideLoadingSpinner();
    }

    function moveToNextCheckoutStep() {
        window.location.href = nextStep;
    }

    function containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++)
            if (list[i].id == obj.id) return true;
        return false;
    }

    function combineAddressObjects(billingAddress, shippingAddress) {
        validateAddresses();

        return addresses = {
            BillingAddress: $scope.billingAddress.Address,
            BillingAddress2: $scope.billingAddress.Address2,
            BillingAddressType: "billing",
            BillingCountry: $scope.billingAddress.Country,
            BillingCity: $scope.billingAddress.City,
            BillingCompany: $scope.billingAddress.Company,
            BillingEmail: $scope.billingAddress.Email,
            BillingFirstName: $scope.billingAddress.FirstName,
            BillingLastName: $scope.billingAddress.LastName,
            BillingPhone: $scope.billingAddress.Phone,
            BillingPostalCode: $scope.billingAddress.PostalCode,
            BillingStateRegion: $scope.billingAddress.StateRegion,
            ShippingAddress: $scope.shippingAddress.Address,
            ShippingAddress2: $scope.shippingAddress.Address2,
            ShippingAddressType: "shipping",
            ShippingCountry: $scope.shippingAddress.Country,
            ShippingCity: $scope.shippingAddress.City,
            ShippingCompany: $scope.shippingAddress.Company,
            ShippingEmail: $scope.shippingAddress.Email,
            ShippingFirstName: $scope.shippingAddress.FirstName,
            ShippingLastName: $scope.shippingAddress.LastName,
            ShippingPhone: $scope.shippingAddress.Phone,
            ShippingPostalCode: $scope.shippingAddress.PostalCode,
            ShippingStateRegion: $scope.shippingAddress.StateRegion
        };
    }

    function restoreShippingMethodChosen() {
        for (var i = 0; i < $scope.shippingMethods.length; i++)
            if ($scope.shippingMethods[i].ServiceCode == $scope.cartOrder.ShippingServiceCode) {
                $scope.shippingMethodChosen.ServiceCode = $scope.shippingMethods[i].ServiceCode;
                $scope.shippingMethodChosen.ServiceName = $scope.shippingMethods[i].ServiceName;
                $scope.shippingMethodChosen.ServicePrice = $scope.shippingMethods[i].ServicePrice;
                $scope.shippingMethodChosen.ShippingCarrierName = $scope.shippingMethods[i].ShippingCarrierName;
                $scope.shippingMethodChosen.ShippingMethodId = $scope.shippingMethods[i].ShippingMethodId;
                $scope.shippingMethodChosen.ShippingServiceName = $scope.shippingMethods[i].ShippingServiceName;
                setTimeout(function () {
                    if ($scope.shippingMethodChosen.ServiceName.length > 0)
                        $('[data-service-code="' + $scope.shippingMethodChosen.ServiceCode + '"]').prop("checked", true);
                }, 200)
                return;
            }
    }

    function scrollTo(elem, offset) {
        var width = $("html").width();
        var position = $(elem).position();
        if (width < 998)
            $(window).scrollTop(position.top - offset, "slow");
        else
            $(document).scrollTop(position.top - offset, "slow");
    }

    function displayErrorMessageFromServer(message) {
        if (message == null || message == undefined)
            message = 'We encountered an error while processing the order. Please contact us at 605-996-5011 to verify that your order is processed correctly. We are sorry for the inconvenience!';
        $scope.errors = [];
        $("#place-order").html($scope.nextStepHtml);
        $scope.errors.push(message);
        $(".checkout-errors").show();
        displayErrors();
    }

    function errorHandling(message, display) {
        debugger;
        $scope.errors = [];

        if ($scope.CartOrder != null)
            message = config.checkout.errorMessageWithEmailAndOrderNumber.replace("{orderNumberForCartError}", $scope.CartOrder.orderNumber);
        else
            message = config.checkout.errorMessageWithEmail;
        $("#place-order").html($scope.nextStepHtml);
        $scope.errors.push(message);
        $(".checkout-errors").show();
        displayErrors();
    }

    function loadReviewStep() {
        $("#loading-message").show();
        $("#checkout").hide();
        $scope.paymentIsVisible = false;
        $("#next-step").html($scope.nextStepHtml);
        setTimeout(function () {
            $("#checkout-review").show();
            $("#checkout").hide().fadeIn("slow");
            $("#loading-message").hide();
        }, 500);
    }

    function loadPaymentStep() {
        $("#loading-message").show();
        $("#checkout").hide();
        $("#checkout-review").hide();
        $("#next-step").html($scope.nextStepHtml);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        $timeout(() => {
            $scope.paymentIsVisible = true;
            $("#checkout").hide().fadeIn("slow");
            $("#loading-message").hide();
            hideLoadingSpinner();
        }, 500)
    }

    function isInternationalOrder(billingData) {
        return $scope.billingAddress.Country != 'US' || $scope.shippingAddress.Country != 'US' || (billingData && billingData.billing_country && billingData.billing_country != 'US');
    }
  
    function validateAddresses() {
        if (isInternationalOrder()) {
            for (var prop in $scope.billingAddress) $scope.shippingAddress[prop] = $scope.billingAddress[prop];
            localStorage.setItem('ShippingDifferent', "false");
            $("#ShippingDifferent").prop("checked", false);
            $scope.ShippingDifferent = false;
        }
        if (!$scope.ShippingDifferent) {
            $scope.shippingAddress = $scope.billingAddress;
        }
    }

    function enableCheckoutAutoAddress() {
        $.checkoutAutoAddress = function (element, options) {

            setTimeout(function () {
                $(".pac-container").prependTo(".address-container");
            }, 300);

            var isRad = function (el) {
                if (el === undefined) return;
                if ($(el).hasClass('autocomplete')) return false;
                var rad = typeof ($find) !== 'undefined' ? $find(el.id) : null;
                if (rad != null) return true;
                return false;
            }

            var getInput = function (name) {
                var el = $(name, element)[0];
                if (el !== undefined && isRad(el)) return el;

                if (!$(el).is('input') && !$(el).is('select'))
                    el = $(name + ' input', element)[0];
                return el;
            }

            var getStreetAddress = function (components) {
                var street_number, route;
                for (var x = 0; x < components.length; x++) {
                    if (components[x].types[0] == 'street_number')
                        street_number = components[x].long_name;
                    if (components[x].types[0] == 'route')
                        route = components[x].long_name;
                }

                street_number = street_number ? street_number : '';
                route = route ? route : '';

                return street_number + ' ' + route;
            }

            var defaults = {
                autoComplete: null,
                placeHolderText: 'Enter your Address',
                autoCompleteTextBox: getInput('.autocomplete'),
                componentForm: {
                    street_number: 'short_name',
                    route: 'long_name',
                    locality: 'long_name',
                    country: 'long_name',
                    administrative_area_level_1: 'short_name',
                    postal_code: 'short_name'
                }
            }

            // to avoid confusions, use "plugin" to reference the 
            // current instance of the object
            var plugin = this;

            // this will hold the merged default, and user-provided options
            // plugin's properties will be available through this object like:
            // plugin.settings.propertyName from inside the plugin or
            // element.data('pluginName').settings.propertyName from outside the plugin, 
            // where "element" is the element the plugin is attached to;
            plugin.settings = {}

            var $element = $(element), // reference to the jQuery version of DOM element
                element = element;    // reference to the actual DOM element

            plugin.init = function () {
                // the plugin's final properties are the merged default and 
                // user-provided options (if any)
                plugin.settings = $.extend({}, defaults, options);
                plugin.settings.autoComplete = new google.maps.places.Autocomplete(
                    plugin.settings.autoCompleteTextBox,
                    { types: ['geocode'] });

                var placeholder = $(plugin.settings.autoCompleteTextBox).attr('placeholder');
                if (typeof (placeholder) === 'undefined')
                    $(plugin.settings.autoCompleteTextBox).attr('placeholder', plugin.settings.placeholderText)
                plugin.settings.autoComplete.addListener('place_changed', fillInAddress);
            }

            var fillInAddress = function () {
                // Get the place details from the autocomplete object.
                var place = plugin.settings.autoComplete.getPlace();

                if (!place.address_components) {
                    if ($element.attr("id") == "billing-address") {
                        $("#billing-address-tooltip").popover("show");
                        $("#billing-address-tooltip ~ .popover").click(function () {
                            $("#billing-address-tooltip").popover("hide");
                        });

                        setTimeout(function () {
                            $("#billing-address").keyup(function () {
                                if ($("#billing-address-tooltip ~ .popover:visible")) {
                                    $("#billing-address-tooltip").popover("hide");
                                    $("#billing-address").unbind("keyup");
                                }
                            });
                        }, 500);
                        $scope.billingAddress.Address = "";
                        $scope.billingAddress.Address2 = "";
                        $scope.billingAddress.City = "";
                        $scope.billingAddress.StateRegion = "";
                        $scope.billingAddress.PostalCode = "";

                    }
                    else if ($element.attr("id") == "shipping-address") {
                        $("#shipping-address-tooltip").popover("show");
                        $("#shipping-address-tooltip ~ .popover").click(function () {
                            $("#shipping-address-tooltip").popover("hide");
                        });
                        setTimeout(function () {
                            $("#shipping-address").keyup(function () {
                                if ($("#shipping-address-tooltip ~ .popover:visible")) {
                                    $("#shipping-address-tooltip").popover("hide");
                                    $("#shipping-address").unbind("keyup");
                                }
                            });
                        }, 500);
                        $scope.shippingAddress.Address = "";
                        $scope.shippingAddress.Address2 = "";
                        $scope.shippingAddress.City = "";
                        $scope.shippingAddress.StateRegion = "";
                        $scope.shippingAddress.PostalCode = "";
                    }

                    $scope.$apply();
                    return;
                }
                place.address_components = place.address_components.reverse(); // put country before state.

                for (var component in plugin.settings.componentForm) {
                    var input = getInput('.' + component);
                    if (input !== undefined) {
                        if (!isRad(input)) {
                            getInput('.' + component).value = '';
                            getInput('.' + component).disabled = false;
                        } else {
                            // Do rad resets.
                            var rad = typeof ($find) !== 'undefined' ? $find(input.id) : null;
                            if (!rad) continue;
                            if (!rad.findItemByText)
                                rad.set_value('');
                        }
                    }
                }
                for (var i = 0; i < place.address_components.length; i++) {
                    var streetAddress = getStreetAddress(place.address_components);

                    var component_types = place.address_components[i].types;
                    for (var j = 0; j < component_types.length; j++) {
                        var addressType = component_types[j];
                        if (plugin.settings.componentForm[addressType]) {
                            var val = place.address_components[i][plugin.settings.componentForm[addressType]];
                            var input = getInput('.' + addressType);
                            if (addressType == 'route' || addressType == 'street_number') {
                                if (input !== undefined)
                                    input.value = streetAddress;
                                if (input.id == "BillingAddress")
                                    $scope.billingAddress.Address = streetAddress;
                                else
                                    $scope.shippingAddress.Address = streetAddress;
                            }
                            else {
                                if (input !== undefined) {
                                    if ($(input).is('select')) {
                                        if (input.id == "BillingState" || input.id == "ShippingState")
                                            $(input).val(val);
                                        else
                                            $(input).val(getCountryCode(val));
                                        updateScope(input);
                                    } else {
                                        input.value = val;
                                        updateScope(input);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            var geolocate = function () {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        var geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        var circle = new google.maps.Circle({
                            center: geolocation,
                            radius: position.coords.accuracy
                        });
                        plugin.settings.autoComplete.setBounds(circle.getBounds());
                    });
                }
            }

            var createAutocompleteTextBox = function () {
                var el = $('<input placeholder="Enter your address" type="text" />');
                $element.append(el);
                el.on('focus', function () {
                    geolocate();
                });
                return el;
            }

            updateScope = function (input) {
                var type = input.id;
                switch (type) {
                    case "BillingAddress":
                        $scope.billingAddress.Address = input.value;
                        break;
                    case "BillingAddress2":
                        $scope.billingAddress.Address2 = input.value;
                        break;
                    case "BillingCity":
                        $scope.billingAddress.City = input.value;
                        break;
                    case "BillingState":
                        $scope.billingAddress.StateRegion = input.value;
                        break;
                    case "BillingZip":
                        $scope.billingAddress.PostalCode = input.value;
                        break;
                    case "BillingCountry":
                        $scope.billingAddress.Country = input.value;
                        break;
                    case "ShippingAddress":
                        $scope.shippingAddress.Address = input.value;
                        break;
                    case "ShippingAddress2":
                        $scope.shippingAddress.Address2 = input.value;
                        break;
                    case "ShippingCity":
                        $scope.shippingAddress.City = input.value;
                        break;
                    case "ShippingState":
                        $scope.shippingAddress.StateRegion = input.value;
                        break;
                    case "ShippingZip":
                        $scope.shippingAddress.PostalCode = input.value;
                        break;
                    case "ShippingCountry":
                        $scope.shippingAddress.Country = input.value;
                        break;
                    default:
                }
                $scope.$apply();
            }

            getStateCode = function (state) {
                for (var i = 0; i < $scope.States.length; i++)
                    if ($scope.States[i].Name == state)
                        return $scope.States[i].IsoCode;
                return "";
            }

            getCountryCode = function (country) {
                for (var i = 0; i < $scope.Countries.length; i++)
                    if ($scope.Countries[i].Name == country)
                        return $scope.Countries[i].IsoCode;
                return "";
            }

            plugin.init();
        }
    }
}