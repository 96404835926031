$(function () {
    var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    var submitButton = $('.email-signup .btn-submit.form-group > button');
    var emailInput = $('.email-signup .email.form-group input');

    if (submitButton !== null)
        submitButton.prop('disabled', true);
    if (emailInput !== null)
        emailInput.on('input propertychange paste', registerEmailChange);

    function registerEmailChange() {
        if (regex.test($(this).val())) {
            $('.email-signup .btn-submit.form-group > button').prop('disabled', false);
        } else {
            $('.email-signup .btn-submit.form-group > button').prop('disabled', true);
        }
    }
});