angular.module('boyds').controller('cartController', cartController);

cartController.$inject = ['$rootScope', 'config', 'windowService', '$http', '$q'];

function cartController($rootScope, config, windowService, $http, $q) {
	var vm = this;
	vm.config = config;
	vm.checkoutErrorMessage = false;
	$rootScope.DisplayPageLoadingSpinner = false;

	vm.checkout = checkout;
	vm.update = update;
	vm.clear = clear;
	vm.showLoadingSpinner = showLoadingSpinner;
	vm.hideLoadingSpinner = hideLoadingSpinner;
	vm.showCheckoutErrorMessage = showCheckoutErrorMessage;

	angular.element("input[name$='CouponCodeApplyButton']").on('click', showLoadingSpinner);
	angular.element("a[id$='RemoveCouponCodeLink']").on('click', showLoadingSpinner);
	angular.element("input[name$='checkoutButton']").on('click', showLoadingSpinner);

	var ApplyCouponObserver = new MutationObserver(applyCoupon);

	var ApplyCouponTarget = document.querySelector('.CouponCodeEntryView');

	function clearShoppingCart() {
		return $http({
			url: config.service.clearShoppingCart.url,
			method: 'POST',
			contentType: "application/json",
			data: null
		}).then(function successCallback() {
			return null;
		});
	};

	ApplyCouponObserver.observe(ApplyCouponTarget, {
		attributes: true,
		childList: true,
		characterData: true,
		subtree: true,
		attributeOldValue: true,
		characterDataOldValue: true
	});

	function applyCoupon(mutations) {
		hideLoadingSpinner();
		angular.element("input[name$='CouponCodeApplyButton']").off('click', showLoadingSpinner);
		angular.element("input[name$='CouponCodeApplyButton']").on('click', showLoadingSpinner);
		angular.element("a[id$='RemoveCouponCodeLink']").off('click', showLoadingSpinner);
		angular.element("a[id$='RemoveCouponCodeLink']").on('click', showLoadingSpinner);
		angular.element("input[name$='checkoutButton']").off('click', showLoadingSpinner);
		angular.element("input[name$='checkoutButton']").on('click', showLoadingSpinner);
	}

	function checkout() {
		showLoadingSpinner();
	}

	function update() {
		showLoadingSpinner();
	}

	function clear() {
		showLoadingSpinner();

		var promises = [];
		promises.push(clearShoppingCart().then(function () {
		}));
		$q.all(promises).then(function success(data) {
			hideLoadingSpinner()
			windowService.refreshPage();
		}, function fail(err) {
			hideLoadingSpinner()
			console.log(err);
		});
		// old code, didnt work // windowService.setCookie('shoppingCartId=; expires=Thu, 01 Jan 2000 00:00:01 GMT;');
	}

	$rootScope.clearShoppingCart = function () {
		promises.push(service.clearShoppingCart().then(function () {
		}));
	}

	function showLoadingSpinner() {
		$rootScope.DisplayPageLoadingSpinner = true;
		try { $rootScope.$apply(); } catch (e) {
			console.log(e); }
	}

	function hideLoadingSpinner() {
		$rootScope.DisplayPageLoadingSpinner = false;
		try { $rootScope.$apply(); } catch (e) { }
	}

	function showCheckoutErrorMessage() {
		vm.checkoutErrorMessage = true;
	}

	return vm;
};