angular.module("boyds").directive('slickSlider', function () {
    return {
        restrict: 'A',
        link: function (scope, element) {
            if ($(element).parent().hasClass("slick-slider")) {

                $(element).parent().slick("unslick");
            }

            $(element).parent().slick({
                infinite: false,
                arrows: true,
                variableWidth: true,
                easeing: "",
                swipeToSlide: true,
                mobileFirst: true,
                touchThreshold: 70,
                //edgeFriction: 1,
                responsive: [
                {
                    breakpoint: 1050,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2
                    }
                }
                ]
            });

        }
    };
});