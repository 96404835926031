angular.module("boyds").factory('config', function () {
    return {
        defaults: {
            guid: '00000000-0000-0000-0000-000000000000',
            gunstockInitialEngravingKeyPhrase: 'FONT'
        },
        pages: {
            titles: {
                home: 'home',
                userRegistration: 'user-registration'
            },
            routes: {
                home: '/home',
                userRegistration: '/user-registration'
            },
            customSearchResults: 'custom-search-results',
            productConfigurator: 'product-configurator',
            products: 'products',
            login: 'sign-in',
            productUrlName: '/product-configurator/product-url-name',
            pathNameOnProductDetail: '/accessories/product-url-name',
            productDetail: 'accessories',
            accessories: 'accessories',
            cart: 'cart',
            checkout: 'checkout',
            gunstocks: 'gunstocks',
            recommendedProducts: 'recommended-products'
        },
        customizations: {
            CustomWoods: {
                value: 'CustomWoods',
                display: 'Wood',
                displayShort: 'Wood',
                description: 'Laminate blanks, made up of many thin layers dyed and bonded, designed for exceptional strength, stability, and unconditional use in any environment.  Solid hardwood options give the traditional look back to your gun.'
            },
            CustomFinishes: {
                value: 'CustomFinishes',
                display: 'Finish',
                displayShort: 'Finish',
                description: 'A variety of superior finishes for each wood type and tested for all environments.'
            },
            CustomLops: {
                value: 'CustomLops',
                display: 'Length of Pull',
                displayShort: 'LOP',
                description: 'Proper length of pull gives you quick sight acquisition, better control and better accuracy.  Length of pull is the distance from the front surface of the trigger to the middle of the buttstock. The thickness of the pad is included in the final measurement.'
            },
            CustomPads: {
                value: 'CustomPads',
                display: 'Recoil Pad',
                displayShort: 'Pad',
                description: 'A custom pad can reduce recoil, fatigue and assures a solid grip while firing your rifle.  It can also add to the look and comfort of your firearm.'
            },
            CustomTips: {
                value: 'CustomTips',
                display: 'Tip',
                displayShort: 'Tip',
                description: 'Custom tips are available in straight or angled cut in a variety of materials and adhere to the end of the forearm.'
            },
            CustomGrips: {
                value: 'CustomGrips',
                display: 'Grip Cap',
                displayShort: 'Grip',
                description: 'A custom grip gives your stock a polished look by adding a small grip cap accent made from nylon or natural woods.'
            },
            CustomAccents: {
                value: 'CustomAccents',
                display: 'White Line Spacer',
                displayShort: 'Spacer',
                description: 'A white line spacer kit is a thin white plastic accent between your stock and your grip, buttpad and tip.  Creates a touch of class for your stock.'
            },
            CustomOther: {
                value: 'CustomOther',
                display: 'Pillar Bedding',
                displayShort: 'Pillar Bedding',
                description: 'Other customization options available for this stock.'
            },
            LaserBasics: {
                value: 'LaserBasics',
                display: 'Laser Engraving',
                displayShort: 'Laser',
                description: 'Custom laser engraving is an option for grips or forend panels. It enhances the look of your firearm and can also add functionality by giving the shooter a textured surface to grip.'
            },
            AdjustableCombs: {
                value: 'AdjustableCombs',
                display: 'Adjustable Comb',
                displayShort: 'Comb',
                description: 'Adjustable Combs help you with your sighting plane.  They adjusts up to 1/2 inch above the surrounding comb height and the adjustment is made with 2 allen wrench screws that are recessed into the top of the comb.'
            }
        },
        filters: {
            currency: 'currency'
        },
        events: {
            itemAddedToCart: 'ItemAddedToCart',
            updateCustomSearch: 'UpdateCustomSearch',
            productFiltersCallback: 'ProductFiltersCallback'
        },
        images: {
            defaults: {
                gun: '/ResourcePackages/Boyds/assets/dist/images/generic-gun.png'
            },
            thumbnails: {
                customOption: '.tmb-customopt',
                gunstock: '.tmb-gunstock',
                silhouette: '.tmb-silhouette'
            },
            configurator: {
                selectGunstock: '/ResourcePackages/Boyds/assets/dist/images/configurator-gunstock-selected.png',
                selectGunstockMobile: '/ResourcePackages/Boyds/assets/dist/images/configurator-bar-mobile.png',
                selectShape: '/ResourcePackages/Boyds/assets/dist/images/configurator-shapes.png',
                selectShapeMobile: '/ResourcePackages/Boyds/assets/dist/images/configurator-shapes-mobile.png',
                customizeGunstock: '/ResourcePackages/Boyds/assets/dist/images/configurator-custom-options-padded.png',//configurator-selected-options.png
                customizeGunstockMobile: '/ResourcePackages/Boyds/assets/dist/images/configurator-custom-options-mobile.png'
            }
        },
        inputPatterns: {
            productQuantity: '^[\\d]*$',
            email: '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$',
            phone: '^[+]*[1]{0,1}[- \.\/]{0,1}[(]{0,1}[0-9]{3}[)]{0,1}[ ]{0,1}[- \.\/0-9]{4,12}$',
            htmlTags: /<[^>]+>|&nbsp;/gm,
            multipleSpaces: /\s{2,}/gm,
            engraving: /[^A-Za-z0-9]/g
        },
        states: [
            { Name: 'Alabama', IsoCode: 'AL' },
            { Name: 'Alaska', IsoCode: 'AK' },
            { Name: 'Arizona', IsoCode: 'AZ' },
            { Name: 'Arkansas', IsoCode: 'AR' },
            { Name: 'California', IsoCode: 'CA' },
            { Name: 'Colorado', IsoCode: 'CO' },
            { Name: 'Connecticut', IsoCode: 'CT' },
            { Name: 'Delaware', IsoCode: 'DE' },
            { Name: 'District Of Columbia', IsoCode: 'DC' },
            { Name: 'Florida', IsoCode: 'FL' },
            { Name: 'Georgia', IsoCode: 'GA' },
            { Name: 'Hawaii', IsoCode: 'HI' },
            { Name: 'Idaho', IsoCode: 'ID' },
            { Name: 'Illinois', IsoCode: 'IL' },
            { Name: 'Indiana', IsoCode: 'IN' },
            { Name: 'Iowa', IsoCode: 'IA' },
            { Name: 'Kansas', IsoCode: 'KS' },
            { Name: 'Kentucky', IsoCode: 'KY' },
            { Name: 'Louisiana', IsoCode: 'LA' },
            { Name: 'Maine', IsoCode: 'ME' },
            { Name: 'Maryland', IsoCode: 'MD' },
            { Name: 'Massachusetts', IsoCode: 'MA' },
            { Name: 'Michigan', IsoCode: 'MI' },
            { Name: 'Minnesota', IsoCode: 'MN' },
            { Name: 'Mississippi', IsoCode: 'MS' },
            { Name: 'Missouri', IsoCode: 'MO' },
            { Name: 'Montana', IsoCode: 'MT' },
            { Name: 'Nebraska', IsoCode: 'NE' },
            { Name: 'Nevada', IsoCode: 'NV' },
            { Name: 'New Hampshire', IsoCode: 'NH' },
            { Name: 'New Jersey', IsoCode: 'NJ' },
            { Name: 'New Mexico', IsoCode: 'NM' },
            { Name: 'New York', IsoCode: 'NY' },
            { Name: 'North Carolina', IsoCode: 'NC' },
            { Name: 'North Dakota', IsoCode: 'ND' },
            { Name: 'Ohio', IsoCode: 'OH' },
            { Name: 'Oklahoma', IsoCode: 'OK' },
            { Name: 'Oregon', IsoCode: 'OR' },
            { Name: 'Pennsylvania', IsoCode: 'PA' },
            { Name: 'Rhode Island', IsoCode: 'RI' },
            { Name: 'South Carolina', IsoCode: 'SC' },
            { Name: 'South Dakota', IsoCode: 'SD' },
            { Name: 'Tennessee', IsoCode: 'TN' },
            { Name: 'Texas', IsoCode: 'TX' },
            { Name: 'Utah', IsoCode: 'UT' },
            { Name: 'Vermont', IsoCode: 'VT' },
            { Name: 'Virginia', IsoCode: 'VA' },
            { Name: 'Washington', IsoCode: 'WA' },
            { Name: 'West Virginia', IsoCode: 'WV' },
            { Name: 'Wisconsin', IsoCode: 'WI' },
            { Name: 'Wyoming', IsoCode: 'WY' }
        ],
        service: {
            clearShoppingCart: {
                url: "/api/ecommerce/CartOrders/ClearShoppingCart"
            },
            getRecommendedProducts: {
                url: '/api/ecommerce/CartOrders/GetRecommendedProducts'
            },
            getRecommendedProductsCount: {
                url: '/api/ecommerce/CartOrders/GetRecommendedProductsCount/'
            },
            addRecommendedProductToCart: {
                url: '/api/ecommerce/CartOrders/AddRecommendedProductToCart'
            },
            getCartOrderTotal: {
                url: '/api/ecommerce/CartOrders/GetCartOrderTotal/'
            },
            getOrderSubTotal: {
                url: '/api/ecommerce/CartOrders/GetOrderSubTotal/'
            },
            getDealerSubTotal: {
                url: '/api/ecommerce/CartOrders/GetDealerSubTotal/'
            },
            getCheckoutStepOne: {
                url: '/api/ecommerce/CartOrders/GetCheckoutStepOne/'
            },
            getCheckoutStepShipping: {
                url: '/api/ecommerce/CartOrders/GetCheckoutStepShipping/'
            },
            getCheckoutStepPaymentAndReview: {
                url: '/api/ecommerce/CartOrders/GetCheckoutStepPaymentAndReview/'
            },
            getElementsInitializers: {
                url: '/api/ecommerce/CartOrders/GetElementsInitializers/'
            },
            getCheckoutStepReceipt: {
                url: '/api/ecommerce/Orders/GetCheckoutStepReceipt/'
            },
            getCartOrderForCurrentCustomer: {
                url: '/api/ecommerce/CartOrders/GetCartOrderForCurrentCustomer/'
            },
            getCountries: {
                url: '/api/locations/countries'
            },
            getMakes: {
                url: '/api/productconfigurator/makes'
            },
            getModels: {
                url: '/api/productconfigurator/models'
            },
            getActions: {
                url: '/api/productconfigurator/actions'
            },
            getShapes: {
                url: '/api/productconfigurator/shapes'
            },
            getProduct: {
                url: '/api/productconfigurator/product'
            },
            getGunstockClassificationIds: {
                url: '/api/productconfigurator/product/{urlName}'
            },
            getAvailableAddOns: {
                url: '/api/productconfigurator/addons'
            },
            getTotalResultsCount: {
                url: "/api/productsearch/count/"
            },
            getAllProductsCount: {
                url: "/api/productsearch/all/count"
            },
            search: {
                url: "/api/productsearch/"
            },
            searchSuggestions: {
                url: "/api/productsearch/suggestions/"
            },
            searchForOrders: {
                url: "/api/orders/ordersearch/"
            },
            searchForOrdersCount: {
                url: "/api/orders/ordersearchcount/"
            },
            sendPasswordRecoveryEmail: {
                url: "/sign-in/SendPasswordResetEmail?email="
            },
            searchGetMakes: {
                url: "/api/productsearch/getmakes/"
            },
            searchGetModels: {
                url: "/api/productsearch/getmodels/"
            },
            searchGetActions: {
                url: "/api/productsearch/getactions/"
            },
            searchGetShapes: {
                url: "/api/productsearch/getshapes/"
            },
            getAllProducts: {
                url: "/api/productsearch/all"
            },
            addProductToCart: {
                url: '/api/ecommerce/CartOrders/AddProductToCart/'
            },
            addProductsToCart: {
                url: '/api/ecommerce/CartOrders/AddProductsToCart/'
            },
            registerCustomer: {
                url: '/api/ecommerce/Customers/RegisterCustomer'
            },
            getCustomerProfile: {
                url: '/api/ecommerce/Customers/GetProfile'
            },
            updateCustomerProfile: {
                url: '/api/ecommerce/Customers/UpdateProfile'
            },
            addCustomizedGunstockToCart: {
                url: '/api/ecommerce/CartOrders/AddCustomizedGunstockToCart/'
            },
            getCartCount: {
                url: '/api/ecommerce/CartOrders/GetProductsInCartCount/'
            },
            updateCartAddress: {
                url: '/api/ecommerce/CartOrders/UpdateCartAddress/'
            },
            updateCartAddresses: {
                url: '/api/ecommerce/CartOrders/UpdateCartAddresses/'
            },
            updateCartOrderMetaFields: {
                url: '/api/ecommerce/CartOrders/UpdateCartOrderMetaFields/'
            },
            updateCartOrderShippingMethod: {
                url: '/api/ecommerce/CartOrders/UpdateCartOrderShippingMethod/'
            },
            updateCheckoutStepOne: {
                url: '/api/ecommerce/CartOrders/UpdateCheckoutStepOne/'
            },
            createUserAccount: {
                url: '/api/ecommerce/Customers/CreateUserAccount/'
            },
            processOrder: {
                url: '/api/ecommerce/Checkout/ProcessOrder/'
            },
            placeOrder: {
                url: '/api/ecommerce/Checkout/PlaceOrder/'
            },
            getOrderDetails: {
                url: '/api/orderhistory/GetOrderHistoryDetailItems/'
            },
            getOrder: {
                url: '/api/orderhistory/GetOrderHistoryItem/'
            },
            submitPromotionForm: {
                formId: '17cc5771-9fc6-6023-a26e-ff000071b53d',
                url: '/api/v1/sf/forms/submit',
                yesValue: 'Yes',
                noValue: 'No',
                emailFieldName: 'Email',
                optInFieldName: 'OptIn'
            },
            getWorkstations: {
                url: '/api/internal/operationinstructions/GetLiveWorkstations/'
            },
            getWorkstationInstructions: {
                url: '/api/internal/operationinstructions/GetWorkstationInstructions/'
            },
            importParts: {
                url: '/api/internal/operationinstructions/ImportParts/'
            },
            requestHelp: {
                url: '/api/internal/operationinstructions/RequestHelp/'
            },
            voidTransaction: {
                url: '/api/ecommerce/Checkout/VoidTransaction/'
            }
        },
        templates: {
            productFilters: {
                url: '/app/templates/productFiltersTemplate.html'
            },
            searchFilters: {
                url: '/app/templates/searchFiltersTemplate.html'
            },
            labeledDropdown: {
                url: '/app/templates/labeledDropdownTemplate.html'
            },
            loadingBar: {
                url: '/app/templates/loadingBarTemplate.html'
            },
            labeledImage: {
                url: '/app/templates/labeledImageTemplate.html'
            },
            loadingSpinner: {
                url: '/app/templates/loadingSpinnerTemplate.html'
            },
            attachDropdownEvents: {
                url: '/app/templates/attachDropdownEventsTemplate.html'
            },
            pageLoadingSpinner: {
                url: '/app/templates/pageLoadingSpinnerTemplate.html'
            },
            fancybox: {
                url: '/app/templates/fancyboxTemplate.html'
            }
        },
        checkout: {
            errorMessage: "An error has occurred. Please contact us at info@boydsgunstocks.com",
            errorMessageWithEmail: "An error has occurred. Please contact us at <a href='mailto:info@boydsgunstocks.com'>info@boydsgunstocks.com</a>",
            errorMessageWithEmailAndOrderNumber: "An error has occurred. Please contact us at <a href='mailto:info@boydsgunstocks.com'>info@boydsgunstocks.com</a> - Order Number: ({orderNumberForCartError})",
            minPasswordLength: 7,
            phoneNumberFormat: /^(\+?(\d{1}|\d{2}|\d{3})[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}$/,
            emailFormat: /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
            customOrderText:
                "All orders (except Rapid Fire items) are built to order, sent to production immediately after you hit submit. " +
                "Any change/cancellation will incur a 15% cancellation fee. " +
                "Please verify all measurements, make, model, and action <b>before</b> placing your order to ensure the correct stock is built for you. " +
                "Boyds offers a 90 day warranty on all custom orders. " +
                "Outside the 90 days, stocks will not be returned. "
        },
        session: {
            productFiltersObj: 'productFiltersObj'
        },
        messages: {
            gunstockImageUnavailable: 'Image not representative of actual color',
            selectIncompatibleOptionWarning: 'Warning! Selecting this option will cause one of the previous selected options to be reselected.'
        },
        payments: {
            cvvErrorMessage: "Incorrect CVV. Please use the CVV on the back of the card.",
            avsErrorMessage: "Credit card billing address is invalid. Please use the billing address associated with the credit card.",
            declinedErrorMessage: "Credit Card Declined. Confirm credit card information was entered correctly or try using another card.",
            creditCardNetworkErrorMessage: "Invalid credit card network. Use a different credit card with a supported network: Visa, Mastercard, Discover.",
            defaultErrorMessage: "Payment failure, please contact us at 605-996-5011.",
            failedRequestCode: "400000"
        },
        cookie: {
            loginRedirect: 'LoginRedirectToPreviousPage',
            sfTokenId: 'SF-TokenId'
        },
        cookieConsentService: {
            getCookiesForRemoval: {
                url: '/api/public-modules/electivecookies'
            }
        }
    }
});