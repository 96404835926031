angular.module("boyds").controller('customerServiceToolController', customerServiceToolController);
customerServiceToolController.$inject = ['service', '$scope', '$rootScope', 'domService'];

function customerServiceToolController(service, $scope, $rootScope, domService) {
	var vm = this;
	var cssFlavor = ['danger', 'default', 'primary', 'success', 'warning', 'info'];
	var cssFlavorIterator = 0;
	vm.title = "Order Search tool";
	vm.orders = [];
	vm.search = search;
	vm.searchTerm = '';
	vm.currentPage = 1;
	vm.takeCount = 20;
	vm.changePage = changePage;

	init();

	return vm;


	function init() {
		preventFormSubmit();
		preformSearchWhenSearchTermChanges();
	}


	function search(newSearch) {
		if (!vm.searchTerm) return;
		if (newSearch) resetSearchResults();
		startProgressIndicator();

		searchForOrders();

		searchForOrdersCount();
	}

	function searchForOrders() {
		var skipPage = vm.currentPage - 1;
		service.searchForOrders(vm.searchTerm, skipPage, vm.takeCount).then(function (response) {
			vm.orders = response.data;
			applyCalloutFlavor();
			stopProgressIndicator();
		});
	}

	function searchForOrdersCount() {
		service.searchForOrdersCount(vm.searchTerm).then(function (response) {
			vm.totalResultsCount = response.data;
		});
	}

	function applyCalloutFlavor() {
		vm.orders.forEach(function (x) {
			x.calloutFlavor = getCalloutFlavor();
		});
	}

	function getCalloutFlavor() {
		var css = "bs-callout bs-callout-" + cssFlavor[cssFlavorIterator];
		cssFlavorIterator = cssFlavorIterator > 5 ? 0 : cssFlavorIterator + 1;
		return css;
	}

	function startProgressIndicator() {
		$rootScope.DisplayPageLoadingSpinner = true;
	}

	function stopProgressIndicator() {
		$rootScope.DisplayPageLoadingSpinner = false;
	}

	function changePage() {
		domService.scrollTop();
		search();
	}

	function resetSearchResults() {
		vm.currentPage = 1;
	}

	function preventFormSubmit() {
		angular.element(document).find("form").on("submit", function (e) {e.preventDefault();});
	}

	function preformSearchWhenSearchTermChanges() {
		$scope.$watch('vm.searchTerm', function () {search(true);});
	}
}