angular.module("boyds").controller('userRegistrationController', userRegistrationController);

userRegistrationController.$inject = ['$scope', '$rootScope', 'windowService', 'service', 'config'];
function userRegistrationController($scope, $rootScope, windowService, service, config) {
    var vm = this;

    vm.user = {
        firstName: null,
        lastName: null,
        company: null,
        emailAddress: null,
        phoneNumber: null,
        streetAddress: null,
        city: null,
        stateCode: null,
        postalCode: null,
        countryCode: null,
        password: null,
        emailPromotions: true
    };
    vm.address = null;
    vm.countries = null;
    vm.submitError = null;
    vm.states = config.states;

    vm.displayEmailNotification = false;
    vm.displaySubmitError = false
    vm.displayGoToLoginButton = false;
    vm.displayInvalidFormNotification = false;
    vm.displayRegistrationForm = true;

    vm.emailFormat = config.inputPatterns.email;
    vm.phoneFormat = config.inputPatterns.phone;

    vm.addressCallback = addressCallback;
    vm.validatePassword = validatePassword;
    vm.confirmPassword = confirmPassword;
    vm.registerCustomer = registerCustomer;
    vm.routeToLogin = routeToLogin;
    vm.displayStates = displayStates;

    init();

    return vm;

    function init() {
        service.getCountries().then(function (response) {
            vm.countries = response.data;
        });
    }

    function addressCallback(address) {
        $scope.$apply(function () {
            vm.address = address;
            updateUserWithAddress();
        });
    }

    function registerCustomer() {
        clearOutStateWhenNotInUs();
        if (validRegistrationForm()) {
            showLoadingSpinner();
            service.registerCustomer(vm.user).then(registerCustomerSuccess, registerCustomerFailure);
        }
        else {
            vm.displayInvalidFormNotification = true;
        }
    }

    function clearOutStateWhenNotInUs() {
        if (vm.user.countryCode != 'US')
            vm.user.stateCode = null;
    }

    function showLoadingSpinner() {
        $rootScope.DisplayPageLoadingSpinner = true;
        try { $rootScope.$apply(); } catch (e) { }
    }

    function hideLoadingSpinner() {
        $rootScope.DisplayPageLoadingSpinner = false;
        try { $rootScope.$apply(); } catch (e) { }
    }

    function updateUserWithAddress() {
        vm.user.city = vm.address.city ? vm.address.city : null;
        vm.user.postalCode = vm.address.postalCode ? vm.address.postalCode : null;
        vm.user.streetAddress = vm.address.streetAddress ? vm.address.streetAddress : null;
        vm.user.stateCode = vm.address.state && vm.address.state.IsoCode ? vm.address.state.IsoCode : null;
        vm.user.countryCode = vm.address.country && vm.address.country.IsoCode ? vm.address.country.IsoCode : null;
    }

    function registerCustomerSuccess(response) {
        vm.displayRegistrationForm = false;
        vm.displayEmailNotification = true;
        vm.displayGoToLoginButton = true;
        hideLoadingSpinner()
    }

    function registerCustomerFailure(response) {
        vm.submitError = response.data;
        vm.displaySubmitError = true;
        vm.displayInvalidFormNotification = false;
        hideLoadingSpinner()
    }

    function validRegistrationForm() {
        for (var prop in vm.user)
            if (vm.user[prop] === null && prop != 'company')
                return prop == 'stateCode' && vm.user.countryCode != 'US' ? true : false;
        return true;
    }

    function routeToLogin() {
        windowService.setHref(config.pages.login);
    }

    function validatePassword(page) {
        var returnValue = vm.user.password && vm.user.password.length > 6;
        page.registerUser.password.$setValidity("password", returnValue);
        return returnValue;
    }

    function confirmPassword(page, confirm) {
        var returnValue = confirm == vm.user.password;
        page.registerUser.confirmPassword.$setValidity("confirmPassword", returnValue);
        return returnValue;
    }

    function displayStates() {
        return vm.user.countryCode == 'US' || vm.user.countryCode === null;
    }
}