angular.module("boyds").service('searchService', function () {
    function getSearchTerm() {
        var searchTerm = sessionStorage.getItem('searchTerm');
        return searchTerm? searchTerm : '';
    }

    function getSanitizedSearchTerm() {
        return sanitizeForSearch(getSearchTerm());
    }

    function setSearchTerm(newSearchTerm) {
        sessionStorage.setItem('searchTerm', newSearchTerm && typeof newSearchTerm === typeof (typeof 1)? newSearchTerm: '');
    }

    function sanitizeForSearch(value) {
        return value.replace(/[^\sa-zA-Z0-9\-]/g, '_').trim();
    }

    function clearSearchTerm() {
        setSearchTerm('');
    }

    return {
        getSearchTerm: getSearchTerm,
        setSearchTerm: setSearchTerm,
        sanitizeForSearch: sanitizeForSearch,
        getSanitizedSearchTerm: getSanitizedSearchTerm,
        clearSearchTerm: clearSearchTerm
    }
});