angular.module("boyds").controller('productDetailsController', productDetailsController);

productDetailsController.$inject = ['$rootScope', 'config'];
function productDetailsController($rootScope, config) {
    var vm = this;
    var cartCount = $rootScope.cartCount;

    angular.element("input[name$='addToCartButton']").on('click', showLoadingSpinner);
    var observer = new MutationObserver(broadcastCartCount);

    var target = document.querySelector('.sfAddToCartWidget');
    observer.observe(target, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
    });

    function broadcastCartCount(mutations) {
        $rootScope.$broadcast(config.events.itemAddedToCart);
        angular.element("input[name$='addToCartButton']").off('click', showLoadingSpinner);
        angular.element("input[name$='addToCartButton']").on('click', showLoadingSpinner);
        hideLoadingSpinner();
        if (cartCount == $rootScope.cartCount)
            $rootScope.cartCount += Number(angular.element("input[name$='quantity']").val());
    }

    function showLoadingSpinner() {
        $rootScope.DisplayPageLoadingSpinner = true;
        try { $rootScope.$apply(); } catch (e) { }
    }

    function hideLoadingSpinner() {
        $rootScope.DisplayPageLoadingSpinner = false;
        try { $rootScope.$apply(); } catch (e) { }
    }

    return vm;
}
