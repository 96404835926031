angular.module("boyds").service('thumbnailService', ['config', function (config) {

    function asSilhouetteThumbnail(url) {
        return asThumbnail(config.images.thumbnails.silhouette, url);
    }

    function asGunstockThumbnail(url) {
        return asThumbnail(config.images.thumbnails.gunstock, url);

    }

    function asCustomOptionThumbnail(url) {
        return asThumbnail(config.images.thumbnails.customOption, url);
    }

    function asThumbnail(thumbnailExtension, url) {
        if (!url) return url;
        if (url.indexOf('Images.DefaultProductTmb') != -1) return url;
        return url.replace(".jpg", thumbnailExtension + ".jpg").replace(".png", thumbnailExtension + ".png");
    }

    return {
        asSilhouetteThumbnail: asSilhouetteThumbnail,
        asGunstockThumbnail: asGunstockThumbnail,
        asCustomOptionThumbnail: asCustomOptionThumbnail,
        asThumbnail: asThumbnail
    };
}]);