angular.module("boyds").controller('accountProfileController', accountProfileController);

accountProfileController.$inject = ['$scope', '$rootScope', 'service', 'config'];
function accountProfileController($scope, $rootScope, service, config) {
    var vm = this;

    vm.user = {
        id: null,
        firstName: null,
        lastName: null,
        company: null,
        phoneNumber: null,
        streetAddress: null,
        streetAddress2: null,
        city: null,
        stateCode: null,
        postalCode: null,
        countryCode: null,
        emailPromotions: true
    };
    vm.address = {};
    vm.countries = null;
    vm.submitError = null;
    vm.CurrentUserId = null;
    vm.states = config.states;

    vm.displaySaveSuccessNotification = false;
    vm.displayInvalidFormNotification = false;
    vm.isEditing = false;

    vm.emailFormat = config.inputPatterns.email;
    vm.phoneFormat = config.inputPatterns.phone;

    vm.addressCallback = addressCallback;
    vm.updateCustomerProfile = updateCustomerProfile;
    vm.displayStates = displayStates;
    vm.editAccountProfile = editAccountProfile;
    vm.cancelEditAccountProfile = cancelEditAccountProfile;
    vm.init = init;

    init();

    return vm;

    function init() {
        getCountries()
            .then(getCustomerProfile);
    }

    function getCountries() {
        return service.getCountries().then(function (response) {
            vm.countries = response.data;
        });
    }

    function getCustomerProfile() {
        return service.getCustomerProfile(vm.CurrentUserId).then(function (response) {
            vm.user = {
                id: vm.CurrentUserId,
                firstName: response.data.FirstName,
                lastName: response.data.LastName,
                company: response.data.Company,
                phoneNumber: response.data.PhoneNumber,
                streetAddress: response.data.StreetAddress,
                streetAddress2: response.data.StreetAddress2,
                city: response.data.City,
                stateCode: response.data.StateCode,
                postalCode: response.data.PostalCode,
                countryCode: response.data.CountryCode,
                emailPromotions: response.data.EmailPromotions
            };
            getAddressFromUser();
        });
    }

    function getAddressFromUser() {
        vm.address.streetAddress = vm.user.streetAddress;
        vm.address.city = vm.user.city;
        vm.address.postalCode = vm.user.postalCode;
        vm.address.country = getCountryFromUser();
        vm.address.state = getStateFromUser();
    }

    function getCountryFromUser() {
        for (var i = 0; i < vm.countries.length; i++)
            if (vm.countries[i].IsoCode == vm.user.countryCode)
                return vm.countries[i];
        return null;
    }

    function getStateFromUser() {
        for (var i = 0; i < vm.states.length; i++)
            if (vm.states[i].IsoCode == vm.user.stateCode)
                return vm.states[i];
        return null;
    }

    function editAccountProfile() {
        vm.displaySaveSuccessNotification = false;
        vm.isEditing = true;
    }

    function cancelEditAccountProfile() {
        emptyAccountProfile();
        getCustomerProfile();
        vm.isEditing = false;
    }

    function emptyAccountProfile() {
        for (var prop in vm.user)
            vm.user[prop] = null;
        vm.address = {};
    }

    function addressCallback(address) {
        $scope.$apply(function () {
            vm.address = address;
            updateUserWithAddress();
        });
    }

    function updateCustomerProfile() {
        clearOutStateWhenNotInUs();
        if (validRegistrationForm()) {
            showLoadingSpinner();
            service.updateCustomerProfile(vm.user).then(updateCustomerProfileSuccess, updateCustomerProfileFailure);
        }
        else
            vm.displayInvalidFormNotification = true;
    }

    function showLoadingSpinner() {
        $rootScope.DisplayPageLoadingSpinner = true;
        try { $rootScope.$apply(); } catch (e) { }
    }

    function hideLoadingSpinner() {
        $rootScope.DisplayPageLoadingSpinner = false;
        try { $rootScope.$apply(); } catch (e) { }
    }

    function updateUserWithAddress() {
        vm.user.id = vm.CurrentUserId;
        vm.user.city = vm.address.city ? vm.address.city : null;
        vm.user.postalCode = vm.address.postalCode ? vm.address.postalCode : null;
        vm.user.streetAddress = vm.address.streetAddress ? vm.address.streetAddress : null;
        vm.user.stateCode = vm.address.state && vm.address.state.IsoCode ? vm.address.state.IsoCode : null;
        vm.user.countryCode = vm.address.country && vm.address.country.IsoCode ? vm.address.country.IsoCode : null;
    }

    function updateCustomerProfileSuccess(response) {
        vm.displaySaveSuccessNotification = true;
        cancelEditAccountProfile();
        hideLoadingSpinner();
    }

    function updateCustomerProfileFailure(response) {
        vm.submitError = response.data;
        vm.displayInvalidFormNotification = false;
        hideLoadingSpinner();
    }

    function validRegistrationForm() {
        for (var prop in vm.user)
            if (vm.user[prop] === null && prop != 'company' && prop != 'streetAddress2' && prop != 'phoneNumber')
                return prop == 'stateCode' && vm.user.countryCode != 'US' ? true : false;
        return true;
    }

    function displayStates() {
        return vm.user.countryCode == 'US' || vm.user.countryCode === null;
    }

    function clearOutStateWhenNotInUs() {
        if (vm.user.countryCode != 'US')
            vm.user.stateCode = null;
    }
}