angular.module("boyds").directive('labeledDropdown', ['config', function (config) {
    return {
        restrict: 'E',
        scope: {
            options: '=',
            label: '=',
            model: '=',
            change: '=',
            disable: '=',
            isLoading: '=',
            focus: '=',
            unfocus: '='
        },
        templateUrl: config.templates.labeledDropdown.url,
        link: function (scope) {
            scope.onChange = function () {
                scope.change(scope.model);
            }
        }
    };
}]);