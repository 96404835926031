angular.module("boyds").directive('textEllipsis', ['config', function (config) {
    return {
        bindings: {
            textLength: '@',
            text: '@',
            tooltip: '@'
        },
        template: [
        '<span>{{$ctrl.displayText}}</span>',
        '<md-button aria-label="{{::$ctrl.fullText}}" class="md-icon-button" ng-click="$ctrl.click($event)" ng-if="$ctrl.showTooltip">',
          '<md-tooltip class="text-tooltip" md-delay="300" md-direction="{{::$ctrl.tooltip}}">',
            '{{::$ctrl.fullText}}',
          '</md-tooltip>',
          '<md-icon>more_horiz</md-icon>',
        '</md-button>'
        ].join(''),
        controller: function () {
            this.$onInit = function () {
                var length = parseInt(this.textLength || 0) || 30,
                  text = this.text || '';
                if (text && text.length > length) {
                    this.displayText = text.substring(0, length);
                    this.fullText = text;
                    this.showTooltip = true;
                } else {
                    this.displayText = text;
                }
            };
            this.click = function (e) {
                e.preventDefault();
                e.stopPropagation();
            };
        }
    };
}]);