angular.module("boyds").controller('productConfiguratorController', productConfiguratorController);
productConfiguratorController.$inject = ['$rootScope', '$filter', '$anchorScroll', '$timeout', 'config', 'thumbnailService', 'service', 'ngProgressFactory', 'productFiltersService', 'confirmationService'];

function productConfiguratorController($rootScope, $filter, $anchorScroll, $timeout, config, thumbnailService, service, ngProgress, productFiltersService, confirmationService) {
    var vm = this;
    var productFilters = null;

    vm.showAddedToCart = false;
    vm.showInstructions = false;
    vm.showInvalidQuantityMessage = false;
    vm.showInvalidInitialEngravingMessage = false;

    vm.selectedProduct = null;
    vm.productUrlName = null;
    vm.addOns = null;
    vm.selectedAddOnGroup = null;
    vm.selectedMake = null;
    vm.selectedModel = null;
    vm.selectedShape = null;
    vm.runningTotal = null;
    vm.imageUrl = null;
    vm.hasVariationImage = true;
    vm.gunImageUrl = null;
    vm.categoryDescription = null;
    vm.baseModelPrice = null;

    vm.config = config;
    vm.productQuantity = 1;
    vm.productQuantityPattern = config.inputPatterns.productQuantity;
    vm.gunstockImageUnavailable = config.messages.gunstockImageUnavailable;

    vm.shapes = [];
    vm.selectedCustomizations = {};
    vm.gunstockEngravingInitials = null;

    vm.hasSelectedInitialEngraving = hasSelectedInitialEngraving;
    vm.selectShape = selectShape;
    vm.addProductToCart = addProductToCart;
    vm.hasProduct = hasProduct;
    vm.clickAndAddProductToCart = clickAndAddProductToCart;
    vm.selectAddOnGroup = selectAddOnGroup;
    vm.scrollToShapeSelectorName = scrollToShapeSelectorName;
    vm.isCategorySelected = isCategorySelected;
    vm.hasAvailableCustomization = hasAvailableCustomization;
    vm.selectAddOn = selectAddOn;
    vm.isCustomizationSelected = isCustomizationSelected;
    vm.isAnyCustomizationSelectedFor = isAnyCustomizationSelectedFor;
    vm.filtersCallback = filtersCallback;
    vm.getOrderedCustomizations = getOrderedCustomizations;
    vm.getRunningTotal = getRunningTotal;
    vm.removeSelectedCustomOption = removeSelectedCustomOption;
    vm.toggleCustomOptionDescription = toggleCustomOptionDescription;
    vm.hasPrimaryImage = hasPrimaryImage;
    vm.thumbnailService = thumbnailService;
    vm.hasSelectedCustomizations = hasSelectedCustomizations;
    vm.hasAddons = hasAddons;
    vm.getGunstockPrice = getGunstockPrice;
    vm.getFormattedGunstockPrice = getFormattedGunstockPrice;
    vm.formatCurrency = formatCurrency;
    vm.getCustomOptionPrice = getCustomOptionPrice;
    vm.isOnSale = isOnSale;
    vm.getCustomOptionDisplay = getCustomOptionDisplay;
    vm.baseModelPrice = 100;

    vm.isCompatibleWithSelectedOptions = isCompatibleWithSelectedOptions;

    $rootScope.$on(config.events.productFiltersCallback, function () {
        resetProductConfigurator();
        filtersCallback(productFiltersService.getProductFiltersObject());
    });

    init();

    return vm;

    function init() {
        initializeSelectedCustomizations();
        configureProgressBar();
        updateImageUrl();
    }

    function initializeSelectedCustomizations() {
        for (customization in config.customizations)
            vm.selectedCustomizations[customization] = null;
    }

    function configureProgressBar() {
        vm.progressBar = ngProgress.createInstance();
        vm.progressBar.setParent(document.getElementById('header-layout'));
        vm.progressBar.setColor('#bf1e2e');
    }

    function getOrderedCustomizations() {
        return [vm.config.customizations.CustomWoods.value,
            vm.config.customizations.CustomFinishes.value,
            vm.config.customizations.CustomLops.value,
            vm.config.customizations.CustomPads.value,
            vm.config.customizations.CustomTips.value,
            vm.config.customizations.CustomGrips.value,
            vm.config.customizations.CustomAccents.value,
            vm.config.customizations.LaserBasics.value,
            vm.config.customizations.AdjustableCombs.value,
            vm.config.customizations.CustomOther.value].reverse();
    }

    function hasProductImage() {
        return vm.selectedProduct !== null && vm.selectedProduct.PrimaryImageUrl !== null;
    }

    function filtersCallback(filters) {
        startLoadingIdentifiers();
        vm.showInstructions = !filters;
        if (filters) {
            populateLocalFiltersObj(filters);
            setSelectedFilters(filters);
            return getItemsBySelectedFilters(filters)
        }
        else
            completeLoadingIdentifiers();
        resetProductConfigurator();
    }

    function getItemsBySelectedFilters(filters) {
        if (filters.ShapeId && vm.selectedShape)
            return getProduct(filters.ShapeId);
        else if (filters.ActionId)
            return getAllShapes(filters.ActionId)
    }

    function populateLocalFiltersObj(filters) {
        productFilters = filters;
    }

    function setSelectedFilters(filters) {
        vm.selectedMake = filters.selectedMake ? filters.selectedMake : null;
        vm.selectedModel = filters.selectedModel ? filters.selectedModel : null;
        vm.selectedShape = filters.selectedShape ? filters.selectedShape : null;
    }

    function getProduct(id) {
        return service.getProduct(id).then(function (response) {
            vm.selectedProduct = response.data;
            if (vm.selectedProduct === null) return null;
            setupSelectedProductFields();

            return getAvailableAddOns(vm.selectedProduct.Id);
        });
    }

    function setupSelectedProductFields() {
        getRunningTotal();
        if (vm.selectedProduct.Description !== "")
            vm.selectedProduct.Description = sanitizeHtmlToPlainText(vm.selectedProduct.Description);
    }

    function sanitizeHtmlToPlainText(text) {
        return text.replace(config.inputPatterns.htmlTags, ' ').replace(config.inputPatterns.multipleSpaces, ' ').trim();
    }

    function getAvailableAddOns(ProductId) {
        vm.addOns = null;
        return service.getAvailableAddOns(ProductId).then(setupAvailableAddOns);
    }

    function setupAvailableAddOns(response) {
        vm.addOns = response.data;
        preselectFirstCustomOptionGroupIfAvailable();
        preselectStandardCustomOptions();
        vm.isAddOnMenuHidden = false;
        evaluatePrimaryImageUrl();
        vm.getRunningTotal();
        completeLoadingIdentifiers();
    }

    function preselectStandardCustomOptions() {
        selectFirstCompatibleCustomOptionForGroup(config.customizations.CustomWoods.value);
        selectFirstCompatibleCustomOptionForGroup(config.customizations.CustomFinishes.value);
        selectFirstCompatibleCustomOptionForGroup(config.customizations.CustomLops.value);
        selectFirstCompatibleCustomOptionForGroup(config.customizations.CustomPads.value);
    }

    function selectFirstCompatibleCustomOptionForGroup(group) {
        vm.selectedCustomizations[group] = findFirstCompatibleOption(vm.addOns[group]);
        if (vm.selectedCustomizations[group] !== null && vm.selectedCustomizations[group] !== undefined) {
            indicateSelectedOption(vm.selectedCustomizations[group]);
            updateCompatibleSelectedOptions(vm.selectedCustomizations[group]);
            updateCompatibleAvailableOptions(vm.selectedCustomizations[group]);
        }
    }

    function findFirstCompatibleOption(options) {
        for (let i = 0; i < options.length; i++) {
            if (isCompatibleWithSelectedOptions(options[i])) {
                return options[i];
            }
        }
        return null;
    }

    function preselectFirstCustomOptionGroupIfAvailable() {
        if (vm.addOns[config.customizations.CustomWoods.value].length !== 0)
            vm.selectedAddOnGroup = config.customizations.CustomWoods.value;
        else
            vm.selectedAddOnGroup = null;
    }

    function getStandardCustomOption(customOptions) {
        for (var i = 0; i < customOptions.length; i++)
            if (customOptions[i].Price === 0)
                return customOptions[i];
        return customOptions[0];
    }

    function isRequiredCustomOption(fieldName) {
        return fieldName === config.customizations.CustomWoods.value
            || fieldName === config.customizations.CustomFinishes.value
            || fieldName === config.customizations.CustomLops.value
            || fieldName === config.customizations.CustomPads.value;
    }

    function removeSelectedCustomOption(fieldName) {
        if (isRequiredCustomOption(fieldName)) return;
        if (confirmationService.confirmMessage('Remove ' + vm.selectedCustomizations[fieldName].Title + '?')) {
            if(fieldName === config.customizations.LaserBasics.value)
                vm.showInvalidInitialEngravingMessage = false;
            vm.selectedCustomizations[fieldName] = null;
            vm.getRunningTotal();
            evaluatePrimaryImageUrl();
        }
    }

    function getAllShapes(ActionId) {
        service.getShapes(ActionId).then(function (response) {
            vm.shapes = response.data;
            if (productFilters.ShapeId) getPreselectedShape();
            else if (vm.shapes.length > 0) selectShape(vm.shapes[0]);
        });
    }

    function getPreselectedShape() {
        for (var i = 0; i < vm.shapes.length; i++)
            if (vm.shapes[i].Id === productFilters.ShapeId)
                selectShape(vm.shapes[i]);
    }

    function selectShape(shape) {
        if (vm.selectedShape === shape) return;

        scrollToProductName();
        resetFieldsOnShapeChange();
        populateProductFiltersShape(shape);
        vm.filtersCallback(productFilters);
    }

    function scrollToProductName() {
        $anchorScroll('ProductName');
    }

    function scrollToShapeSelectorName() {
        vm.toggleShapeSelector = false;
        $anchorScroll('ShapeSelectorName');
    }

    function resetFieldsOnShapeChange() {
        vm.addOns = null;
        vm.categoryDescription = null;
        vm.showAddedToCart = false;
        vm.selectedCustomizations = {};
        initializeSelectedCustomizations();
    }

    function populateProductFiltersShape(shape) {
        productFilters.selectedShape = shape;
        productFilters.ShapeId = shape.Id;
    }

    function clearSelectedProduct() {
        vm.selectedProduct = null;
        initializeSelectedCustomizations();
    }

    function hasProduct() {
        if (vm.selectedProduct === null) {
            clearMessages()
            resetProductQuantity();
            return false;
        }
        return true;
    }

    function clickAndAddProductToCart() {
        vm.showAddedToCart = false;
        vm.showInvalidQuantityMessage = false;
        vm.showInvalidInitialEngravingMessage = false;
        addProductToCart();
    }

    function addProductToCart() {
        if (vm.productQuantity < 1) {
            vm.showInvalidQuantityMessage = true;
            return;
        }
        if (vm.hasSelectedInitialEngraving() && (!vm.gunstockEngravingInitials || (vm.gunstockEngravingInitials.match(config.inputPatterns.engraving) !== null))) {
            vm.showInvalidInitialEngravingMessage = true;
            return;
        }
        startLoadingIdentifiers();
        assembleProductsAndAdd();
    }

    function assembleProductsAndAdd() {
        if (isStandardGunstock())
            addStandardGunstockToCart(assembleStandardGunstock());
        else
            addCustomizedGunstockToCart(assembleCustomGunstockProducts());
    }

    function assembleStandardGunstock() {
        return {
            ProductId: vm.selectedProduct.Id,
            VariantId: vm.selectedCustomizations.CustomWoods.VariationId,
            Quantity: vm.productQuantity
        };
    }

    function addStandardGunstockToCart(product) {
        service.addProductToCart(product).then(function () {
            productAddedToCartSuccess();
        });
    }

    function assembleCustomGunstockProducts() {
        var products = [];

        products = assembleSelectedProducts(products, vm.selectedProduct);
        products = assembleSelectedProducts(products, vm.selectedCustomizations.CustomWoods);
        products = assembleSelectedProducts(products, vm.selectedCustomizations.CustomFinishes);
        products = assembleSelectedProducts(products, vm.selectedCustomizations.CustomLops);
        products = assembleSelectedProducts(products, vm.selectedCustomizations.CustomAccents);
        products = assembleSelectedProducts(products, vm.selectedCustomizations.CustomGrips);
        products = assembleSelectedProducts(products, vm.selectedCustomizations.CustomPads);
        products = assembleSelectedProducts(products, vm.selectedCustomizations.CustomTips);
        products = assembleSelectedProducts(products, vm.selectedCustomizations.CustomOther);
        products = assembleSelectedProducts(products, vm.selectedCustomizations.AdjustableCombs);
        products = assembleSelectedProducts(products, vm.selectedCustomizations.LaserBasics);

        return products;
    }

    function addCustomizedGunstockToCart(products) {
        service.addCustomizedGunstockToCart(products).then(function () {
            productAddedToCartSuccess();
        });
    }

    function isStandardGunstock() {
        return onlyStandardCustomizationsAreSelected() && hasVariationId() && hasStandardFinish() && hasStandardLop() && hasStandardPad();
    }

    function productAddedToCartSuccess() {
        vm.showAddedToCart = true;
        $rootScope.cartCount++;
        $rootScope.$broadcast(config.events.itemAddedToCart);
        completeLoadingIdentifiers();
        $timeout(function () { vm.showAddedToCart = false; }, 5000);
    }

    function onlyStandardCustomizationsAreSelected() {
        if (hasCustomWood() && hasCustomFinish() && hasCustomLop() && hasCustomPad())
            for (var option in vm.selectedCustomizations)
                if (nonVariationCustomOptionHasValue(option)) return false;
        return true;
    }

    function hasSelectedCustomizations() {
        return hasCustomWood() || hasCustomFinish() || hasCustomLop() || hasCustomPad();
    }

    function hasSelectedInitialEngraving() {
        return hasLaserBasics() && vm.selectedCustomizations.LaserBasics.Title && vm.selectedCustomizations.LaserBasics.Title.toUpperCase().indexOf(config.defaults.gunstockInitialEngravingKeyPhrase) !== -1;
    }

    function hasVariationId() {
        return hasCustomWood() && vm.selectedCustomizations.CustomWoods.VariationId && vm.selectedCustomizations.CustomWoods.VariationId !== config.defaults.guid;
    }

    function hasStandardFinish() {
        return vm.selectedCustomizations.CustomFinishes && vm.selectedCustomizations.CustomFinishes.Price === 0;
    }

    function hasStandardLop() {
        return vm.selectedCustomizations.CustomLops && vm.selectedCustomizations.CustomLops.Price === 0;
    }

    function hasStandardPad() {
        return vm.selectedCustomizations.CustomPads && vm.selectedCustomizations.CustomPads.Price === 0;
    }

    function hasCustomWood() {
        return vm.selectedCustomizations.CustomWoods;
    }

    function hasCustomFinish() {
        return vm.selectedCustomizations.CustomFinishes;
    }

    function hasCustomLop() {
        return vm.selectedCustomizations.CustomLops;
    }

    function hasCustomPad() {
        return vm.selectedCustomizations.CustomPads;
    }

    function hasLaserBasics() {
        return vm.selectedCustomizations.LaserBasics;
    }

    function hasAddons() {
        var length = 0;
        for (prop in vm.addOns)
            if (vm.addOns[prop]) length += vm.addOns[prop].length;
        return length > 0;
    }

    function nonVariationCustomOptionHasValue(option) {
        if (option === config.customizations.CustomWoods.value) return false;
        if (option === config.customizations.CustomFinishes.value) return false;
        if (option === config.customizations.CustomLops.value) return false;
        if (option === config.customizations.CustomPads.value) return false;
        if (vm.selectedCustomizations[option]) return true;
        return false;
    }

    function assembleSelectedProducts(products, selectedProduct) {
        if (selectedProduct && selectedProduct.Id)
            products.push(isGunstockWithVariationImage(selectedProduct) ? getProductWithCustomGunstockImage(selectedProduct) : getProductWithoutCustomGunstockImage(selectedProduct));
        return products;
    }

    function isGunstockWithVariationImage(selectedProduct) {
        return vm.selectedProduct.Id === selectedProduct.Id && !!vm.selectedCustomizations.CustomWoods && !!vm.selectedCustomizations.CustomWoods.GunstockImageUrl;
    }

    function getProductWithCustomGunstockImage(selectedProduct) {
        var product = getProductWithoutCustomGunstockImage(selectedProduct);
        product.CustomGunstockImage = vm.selectedCustomizations.CustomWoods.GunstockImageUrl;
        return product;
    }

    function getProductWithoutCustomGunstockImage(selectedProduct) {
        if (hasSelectedInitialEngraving() && selectedProduct.Title.indexOf(config.defaults.gunstockInitialEngravingKeyPhrase) !== -1)
            return { ProductId: selectedProduct.Id, Quantity: vm.productQuantity, Engraving: vm.gunstockEngravingInitials };
        return {ProductId: selectedProduct.Id, Quantity: vm.productQuantity};
    }

    function selectAddOnGroup(addOnGroup) {
        vm.selectedAddOnGroup = addOnGroup;
        vm.categoryDescription = null;
    }

    function isCategorySelected(key) {
        return vm.selectedAddOnGroup === key;
    }

    function hasAvailableCustomization(addOnGroup) {
        if (vm.addOns === null) return false;
        return vm.addOns[addOnGroup] !== undefined && vm.addOns[addOnGroup].length > 0;
    }

    function isCustomizationSelected(customization) {
        return vm.selectedCustomizations[vm.selectedAddOnGroup] === customization;
    }

    function selectAddOn(selectedOption) {
        if (selectedOption.isCompatible) {
            processSelection(selectedOption);
        } else {
            if (confirmationService.confirmMessage(config.messages.selectIncompatibleOptionWarning)) {
                processSelection(selectedOption);
            }
        }
    }

    function processSelection(selectedOption) {
        clearSelectedOptionIndicators();
        indicateSelectedOption(selectedOption);
        vm.selectedCustomizations[vm.selectedAddOnGroup] = selectedOption;

        updateCompatibleSelectedOptions(selectedOption);
        updateCompatibleAvailableOptions(selectedOption);
        selectCompatibleCustomOptions();

        if (!vm.selectedCustomizations.CustomFinishes) {
            updateCompatibleAvailableOptions(selectedOption);
            selectFirstCompatibleCustomOption(config.customizations.CustomFinishes.value);
        }

        getRunningTotal();
        evaluatePrimaryImageUrl();
    }

    function evaluatePrimaryImageUrl() {
        if (hasCustomWoodImage())
            setVariationImage();
        else
            updateImageUrl();
    }

    function hasCustomWoodImage() {
        return vm.selectedCustomizations.CustomWoods && vm.selectedCustomizations.CustomWoods.GunstockImageUrl;
    }

    function setVariationImage() {
        vm.imageUrl = vm.selectedCustomizations.CustomWoods.GunstockImageUrl;
        vm.hasVariationImage = true;
    }

    function updateImageUrl() {
        if (hasProductImage()) setProductImage()
    }

    function setProductImage() {
        vm.imageUrl = vm.selectedProduct.PrimaryImageUrl;
        vm.hasVariationImage = false;
    }

    function hasGunstockImageUrl() {
        return vm.selectedCustomizations.CustomWoods.GunstockImageUrl;
    }

    function updateStartingPrice() {
        var highestPrice = isStandardGunstock() ? Math.max(getBaseVariationPrice(), vm.selectedProduct.Price) : vm.selectedProduct.Price;
        vm.selectedProduct.FormattedPrice = formatCurrency(highestPrice);
    }

    function getRunningTotal() {
        updateStartingPrice();
        vm.runningTotal = isStandardGunstock() ? getFormattedGunstockPrice() : getFormattedGunstockWithCustomOptionsPrice();
    }

    function getGunstockPrice() {
        return isStandardGunstock() ? getVariationGunstockPrice() : getBaseGunstockPrice();
    }

    function getFormattedGunstockPrice() {
        return formatCurrency(getGunstockPrice());
    }
    function getFormattedGunstockBasePrice() {
        return formatCurrency(getGunstockPrice());
    }

    function getGunstockWithCustomOptionsPrice() {
        return calculateTotalCustomizationPrice() + getGunstockPrice();
    }

    function getFormattedGunstockWithCustomOptionsPrice() {
        return formatCurrency(getGunstockWithCustomOptionsPrice());
    }

    function getBaseGunstockPrice() {
		return getCheapestProductPrice(vm.selectedProduct);
    }

    function getVariationGunstockPrice() {
        return isOnSale(vm.selectedProduct) ? getBaseVariationSalePrice() : getBaseVariationPrice();
    }
    

    function getBaseVariationPrice() {
        return vm.selectedProduct.Price + getVariationAdditionalPrice()
    }


	function getBaseVariationSalePrice() {
		return vm.selectedProduct.SalePrice + getVariationAdditionalPrice()
	}

    function formatCurrency(value) {
        return value ? $filter(config.filters.currency)(value) : value;
    }

    function getVariationAdditionalPrice() {
        return vm.selectedCustomizations.CustomWoods.VariationAdditionalPrice;
    }

    function calculateTotalCustomizationPrice() {
        var totalCustomizationPrice = 0;
        for (var item in vm.selectedCustomizations)
            totalCustomizationPrice += vm.selectedCustomizations[item] ? getCustomOptionPrice(vm.selectedCustomizations[item]) : 0;
        return totalCustomizationPrice;
    }

    function getCustomOptionPrice(customOption) {
        return getCheapestProductPrice(customOption);
    }
    
    function getCheapestProductPrice(product) {
		var price = product.Price;
		if (isOnSale(product)) price = Math.min(product.SalePrice, price);
		if (hasDealerPrice(product)) price =  Math.min(product.DealerPrice, price);
		return price;
	}

    function isAnyCustomizationSelectedFor(addOnGroup) {
        return vm.selectedCustomizations[addOnGroup] !== null;
    }

    function resetProductConfigurator() {
        clearAllSelections();
        resetProductQuantity();
        clearMessages();
        updateImageUrl();
    }

    function clearAllSelections() {
        vm.selectedAddOnGroup = null;
        clearSelectedProduct();
    }

    function resetProductQuantity() {
        vm.productQuantity = 1;
    }

    function clearMessages() {
        vm.showAddedToCart = false;
        vm.showInvalidQuantityMessage = false;
        vm.showInvalidInitialEngravingMessage = false;
    }

    function toggleCustomOptionDescription(key) {
        var clickedDescription = config.customizations[key].description;
        if (vm.categoryDescription !== null && vm.categoryDescription === clickedDescription)
            vm.categoryDescription = null;
        else {
            vm.selectAddOnGroup(key);
            vm.categoryDescription = clickedDescription;
        }
    }

    function hasPrimaryImage(shape) {
        return shape.PrimaryImageUrl;
    }

    function startLoadingIdentifiers() {
        vm.progressBar.start();
        $rootScope.DisplayPageLoadingSpinner = true;
    }

    function completeLoadingIdentifiers() {
        vm.progressBar.complete();
        $rootScope.DisplayPageLoadingSpinner = false;
    }

    function clearSelectedOptionIndicators() {
        for (optionGroup in vm.selectedCustomizations) {
            if (vm.selectedCustomizations.hasOwnProperty(optionGroup) && vm.selectedCustomizations[optionGroup] && vm.selectedCustomizations[optionGroup].IncompatibilityList && vm.selectedCustomizations[optionGroup].IncompatibilityList.indexOf(customization.Sku) !== -1)
                vm.selectedCustomizations[optionGroup].selected = false;
        }
    }

    function indicateSelectedOption(selectedOption) {
        if (selectedOption === null || selectedOption === undefined) return;
        
        if (selectedOption.selectedTimeout) {
            $timeout.cancel(selectedOption.selectedTimeout);
            $timeout(function () {
                selectedOption.selected = false;
            }, 100, true);
        }

        $timeout(function () {
            selectedOption.selected = true;
        }, 100, true);
        selectedOption.selectedTimeout = $timeout(function () {
            selectedOption.selected = false;
        }, 4000, true);
    }

    function isCompatibleWithSelectedOptions(customization) {
        for (optionGroup in vm.selectedCustomizations) {
            if (vm.selectedCustomizations.hasOwnProperty(optionGroup) && vm.selectedCustomizations[optionGroup] && vm.selectedCustomizations[optionGroup].IncompatibilityList && vm.selectedCustomizations[optionGroup].IncompatibilityList.indexOf(customization.Sku) !== -1)
                return customization.isCompatible = false;
        }
        return customization.isCompatible = true;
    }

    function isCompatibleWith(customization1, customization2) {
        return customization1.IncompatibilityList.indexOf(customization2.Sku) === -1;
    }

    function updateCompatibleSelectedOptions(selectedOption) {
        for (optionGroup in vm.selectedCustomizations) {
            if (vm.selectedCustomizations[optionGroup] !== null && vm.selectedCustomizations[optionGroup] !== undefined
                && vm.selectedCustomizations[optionGroup].hasOwnProperty("Sku") && vm.selectedCustomizations[optionGroup].Sku) 
                vm.selectedCustomizations[optionGroup].isCompatible = selectedOption.IncompatibilityList.indexOf(vm.selectedCustomizations[optionGroup].Sku) === -1;
        }
    }

    function updateCompatibleAvailableOptions(selectedOption) {
        for (optionGroup in vm.addOns) {
            if (vm.addOns[optionGroup] !== null ) {
                for (var i = 0; i < vm.addOns[optionGroup].length; i++) {
                    var option = vm.addOns[optionGroup][i];
                    if (option)
                        option.isCompatible = isCompatibleWith(selectedOption, option);
                }
            }
        }
    }

    function selectCompatibleCustomOptions() {
        selectFirstCompatibleCustomOption(config.customizations.CustomWoods.value);
        selectFirstCompatibleCustomOption(config.customizations.CustomFinishes.value);
        selectFirstCompatibleCustomOption(config.customizations.CustomLops.value);
        selectFirstCompatibleCustomOption(config.customizations.CustomPads.value);
        selectFirstCompatibleCustomOption(config.customizations.CustomTips.value);
        selectFirstCompatibleCustomOption(config.customizations.CustomGrips.value);
        selectFirstCompatibleCustomOption(config.customizations.CustomAccents.value);
        selectFirstCompatibleCustomOption(config.customizations.CustomOther.value);
        selectFirstCompatibleCustomOption(config.customizations.LaserBasics.value);
        selectFirstCompatibleCustomOption(config.customizations.AdjustableCombs.value);
    }

    function selectFirstCompatibleCustomOption(group) {
        if (vm.selectedCustomizations[group] !== null && vm.selectedCustomizations[group] !== undefined) {
            if (vm.selectedCustomizations[group].hasOwnProperty("isCompatible") && !vm.selectedCustomizations[group].isCompatible) {
                vm.selectedCustomizations[group] = vm.addOns[group].filter(function (o) { return o.isCompatible || o.isCompatible === undefined })[0];
                indicateSelectedOption(vm.selectedCustomizations[group]);
            }
        }
        else if (group === config.customizations.CustomFinishes.value) {
            vm.selectedCustomizations[group] = vm.addOns[group].filter(function (o) { return o.isCompatible || o.isCompatible === undefined })[0];
            indicateSelectedOption(vm.selectedCustomizations[group]);
        }
    }

    function isOnSale(value) {
        return value && (value.SalePrice || value.SalePrice === 0);
    }

	function hasDealerPrice(value) {
		return value && (value.DealerPrice || value.DealerPrice === 0);
	}

    function getCustomOptionDisplay(optionPrice) {
        return optionPrice === 0 ? "Free" : formatCurrency(optionPrice);
    }
}