angular.module("boyds").directive('labeledImage', ['config', function (config) {
        return {
            restrict: 'E',
            scope: {
                show: '=',
                label: '=',
                src: '='
            },
            templateUrl: config.templates.labeledImage.url 
        };
    }]);