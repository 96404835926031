angular.module('boyds').controller('slideNavigation', slideNavigation);

function slideNavigation() {
    var vm = this;

    vm.navigationIsOpen = false;
    vm.toggleSlideNavigation = toggleSlideNavigation;

    return vm;

    function toggleSlideNavigation() {
        vm.navigationIsOpen = !vm.navigationIsOpen;
    }
}