angular.module("boyds").controller('recommendedProductsController', recommendedProductsController);
recommendedProductsController.$inject = ['$rootScope', 'config', 'service', 'ngProgressFactory', 'windowService', 'ngToast', 'domService'];

function recommendedProductsController($rootScope, config, service, ngProgress, windowService, ngToast, domService) {
    var vm = this;
    vm.recommendedProducts = null;
    vm.recommendedProductsCount = null;

    vm.pageTake = 12;
    vm.currentPage = 1;

    vm.changePage = changePage;
    vm.routeToUrl = routeToUrl;
    vm.addRecommendedProductToCart = addRecommendedProductToCart;
    vm.checkout = checkout;

    init();

    return vm;

    function init() {
        configureProgressBar();
        getRecommendedProductsCount();
        getRecommendedProducts();
    }

    function getRecommendedProducts() {
        vm.progressBar.start();
        return service.getRecommendedProducts(vm.currentPage, vm.pageTake).then(function (response) {
            vm.recommendedProducts = response;
            vm.progressBar.complete();
        });
    }

    function getRecommendedProductsCount() {
        return service.getRecommendedProductsCount().then(function (response) {
            vm.recommendedProductsCount = response;
        });
    }

    function changePage() {
        domService.scrollTop();
        getRecommendedProducts();
    }

    function routeToUrl(product) {
        product.Sku.length >= 12 && product.Sku.endsWith('ZZ') ? windowService.assignLocation(config.pages.productConfigurator, product.UrlName) : windowService.assignLocation(config.pages.accessories, product.UrlName);
    }

    function configureProgressBar() {
        vm.progressBar = ngProgress.createInstance();
        vm.progressBar.setParent(document.getElementById('header-layout'));
        vm.progressBar.setColor('#bf1e2e');
    }

    function addRecommendedProductToCart(product) {
		startLoadingIdentifiers();
        service.addRecommendedProductToCart(product.UrlName).then(function (response) {
			productAddedToCartSuccess();
		});
    }
	
	function productAddedToCartSuccess() {
		vm.showAddedToCart = true;
		$rootScope.cartCount++;
		$rootScope.$broadcast(config.events.itemAddedToCart);
		stopLoadingIdentifiers();
		ngToast.create({content:'<h4><i class="fa fa-check"></i>Item added to Cart!</h4>'});
	}

	function startLoadingIdentifiers() {
		vm.progressBar.start();
		$rootScope.DisplayPageLoadingSpinner = true;
	}

	function stopLoadingIdentifiers() {
		vm.progressBar.complete();
		$rootScope.DisplayPageLoadingSpinner = false;
	}
	
	function checkout() {
		windowService.setHref(config.pages.checkout)
	}
}