angular.module("boyds").controller('searchBoxController', searchBoxController);

searchBoxController.$inject = ['$timeout', '$rootScope', '$scope', 'windowService', 'config', 'service', 'domService', 'searchService'];
function searchBoxController($timeout, $rootScope, $scope, windowService, config, service, domService, searchService) {
    var vm = this;

    vm.searchTerm = getSearchTerm();
    vm.preventKeypressEvent = false;
    vm.products = [];

    vm.search = search;
    vm.searchNow = searchNow;
    vm.getProducts = getProducts;
    vm.selectSearch = selectSearch;

    return vm;

    function getSearchTerm() {
        if (isOnAResultsPage())
            searchService.clearSearchTerm('')
        return searchService.getSearchTerm();
    }

    function isOnAResultsPage() {
        return windowService.isOnPage("/" + config.pages.accessories)
            || windowService.isOnPage("/" + config.pages.productConfigurator)
            || windowService.isOnPage("/" + config.pages.cart)
            || windowService.isOnPage("/" + config.pages.checkout)
            || windowService.isOnPage("/" + config.pages.gunstocks);
    }

    function positionButton() {
        domService.insertAfter('searchBoxButton', 'searchBoxInput');
    }

    function getProducts(searchTerm) {
        if (searchTerm.length > 2)
            return service.searchSuggestions(searchService.sanitizeForSearch(searchTerm))
                .then(function (response) {
                    vm.products = response.data;
                    //$scope.$apply();
                    positionButton();
                    return response.data;
                });
    }

    function searchNow($event) {
        if ($event.keyCode === 13) {
            $event.preventDefault();
            if (vm.preventKeypressEvent)
                vm.preventKeypressEvent = false;
            else
                $timeout(function () {
                    search(vm.searchTerm);
                }, 800);
        }
    }

    function selectSearch() {
        vm.preventKeypressEvent = true;
        search();
    }

    function search(product) {
        vm.searchTerm = product;
        if (searchingObject()) {
            searchService.clearSearchTerm();
            windowService.setHref(config.pages.products, vm.searchTerm.UrlName);
        }
        else if (onCustomSearchResultsPage())
            updateCustomSearchResults();
        else {
            searchService.setSearchTerm(vm.searchTerm);
            windowService.setHref(config.pages.customSearchResults);
        }
    }

    function searchingObject() {
        for (var i = 0; i < vm.products.length; i++) {
            if (vm.products[i].Title == vm.searchTerm.Title) {
                vm.searchTerm = vm.products[i];
                return true;
            }
        }
        return false;
    }

    function updateCustomSearchResults() {
        searchService.setSearchTerm(vm.searchTerm);
        $rootScope.$broadcast(config.events.updateCustomSearch);
    }

    function onCustomSearchResultsPage() {
        return windowService.getHref().indexOf(config.pages.customSearchResults) > -1;
    }
};