angular.module("boyds").factory('domService', function () {
    function attr(element, attribute, value) {
        $(element).attr(attribute, value);
    }

    function removeClass(element, className) {
        $(element).removeClass(className);
    }

    function scrollTop() {
        $('html, body').animate({ scrollTop: 0 });
    }

    function insertAfter(first, second) {
        $('#' + first).insertAfter($('#' + second));
    }

    return {
        attr: attr,
        removeClass: removeClass,
        scrollTop: scrollTop,
        insertAfter: insertAfter
    }
});