angular.module("boyds").directive('focusInput', function () {
    return {
        restrict: 'A',
        scope: {
            trigger: '=focus'
        },
        link: function (scope, element) {
            scope.$watch('trigger', function (value) {
                if (value === true) {
                    element[0].focus();

                    if (element.find("option").length > 12)
                        element.attr("size", 12);
                    else
                        element.attr("size", element.find("option").length);

                    element.addClass("floating-select");
                } else {
                    element.attr("size", 0);
                    element.removeClass("floating-select");
                }
            });
        }
    };
});