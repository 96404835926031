angular.module("boyds").directive('searchFilters', ['config', function (config) {
    return {
        restrict: 'E',
        scope: {
            searchTerm: '=',
            preselectFilter: '=',
            callback: '='
        },
        controller: 'searchFiltersController',
        controllerAs: 'vm',
        bindToController: true,
        templateUrl: config.templates.searchFilters.url
    };
}]);