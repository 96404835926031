angular.module("boyds").service('windowService', ['$window', function ($window) {
    function getPathName() {
        return $window.location.pathname;
    }

    function assignLocation(page, name) {
        $window.location.assign($window.location.origin + "/" + page + "/" + name);
    }

    function setLocation(location) {
        $window.location = location;
    }

    function setHref(page, name) {
        $window.location.href = "/" + page + "/" + (name ? name : "");
    }

    function getHref() {
        return $window.location.href;
    }

    function setCookie(cookie) {
        $window.document.cookie = cookie;
    }

    function refreshPage() {
        $window.location.reload();
    }

    function isOnPage(page) {
        return $window.location.href.indexOf(page) != -1;
    }

    return {
        getPathName: getPathName,
        assignLocation: assignLocation,
        setHref: setHref,
        getHref: getHref,
        setCookie: setCookie,
        refreshPage: refreshPage,
        isOnPage: isOnPage,
        setLocation: setLocation
    };
}]);