angular.module("boyds").directive('productFilters', ['config', function (config) {
    return {
        restrict: 'E',
        scope: {
            preselectFilter: '='
        },
        controller: 'productFiltersController',
        controllerAs: 'vm',
        bindToController: true,
        templateUrl: config.templates.productFilters.url,
    };
}]);