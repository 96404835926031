angular.module("boyds").controller('customSearchResultsController', customSearchResultsController);

customSearchResultsController.$inject = ['$scope', '$rootScope', 'windowService', 'service', 'config', 'domService', 'ngProgressFactory', 'searchService'];
function customSearchResultsController($scope, $rootScope, windowService, service, config, domService, ngProgress, searchService) {
    var vm = this;
    var searchFilters = null;

    vm.originalSearchTerm = null;
    vm.sanitizedSearchTerm = null;
    vm.totalResultsCount = null;
    vm.searchResults = null;
    vm.pageTake = 6;
    vm.loadingCount = true;

    vm.filtersCallback = filtersCallback;
    vm.showSearchResults = showSearchResults;
    vm.changePage = changePage;
    vm.routeToUrl = routeToUrl;

    $scope.$on(config.events.updateCustomSearch, init);
    configureProgressBar();

    init();

    return vm;

    function init() {
        vm.currentPage = 1;
        findOriginalSearchTerm();
        getResultsCount();
        getSearchResults();
    }

    function findOriginalSearchTerm() {
        vm.originalSearchTerm = searchService.getSearchTerm();
        vm.sanitizedSearchTerm = searchService.getSanitizedSearchTerm();
    }

    function getResultsCount() {
        vm.loadingCount = true;
        if (validSearchTerm())
            return getTotalResultsCount();
        return getAllProductsCount();
    }

    function validSearchTerm() {
        return vm.originalSearchTerm;
    }

    function getTotalResultsCount() {
        return service.getTotalResultsCount(searchService.getSanitizedSearchTerm(), getSelectedMake(), getSelectedModel(), getSelectedAction(), getSelectedShape()).then(function (response) {
            vm.totalResultsCount = response.data;
            vm.loadingCount = false;
        });
    }

    function getAllProductsCount() {
        return service.getAllProductsCount(getSelectedMake(), getSelectedModel(), getSelectedAction(), getSelectedShape()).then(function (response) {
            vm.totalResultsCount = response.data;
            vm.loadingCount = false;
        });
    }

    function getSearchResults() {
        vm.progressBar.start();
        vm.searchResults = null;

        if (validSearchTerm())
            return search();
        return getAllProducts();
    }

    function search() {
		startProgressIndicator();
        return service.search(searchService.getSanitizedSearchTerm(), vm.currentPage, vm.pageTake, getSelectedMake(), getSelectedModel(), getSelectedAction(), getSelectedShape()).then(function (response) {
            vm.searchResults = response.data;
			stopProgressIndicator();
            vm.progressBar.complete();
        });
    }

    function getAllProducts() {
        return service.getAllProducts(vm.currentPage, vm.pageTake, getSelectedMake(), getSelectedModel(), getSelectedAction(), getSelectedShape()).then(function (response) {
            vm.searchResults = response.data;
            vm.progressBar.complete();
        });
    }

    function routeToUrl(product) {
        (productIsConfigurable(product)) ? windowService.assignLocation(config.pages.productConfigurator, product.UrlName) : windowService.assignLocation(config.pages.products, product.UrlName)
    }

    function productIsConfigurable(product) {
        return product.Shape;
    }

    function showSearchResults() {
        return vm.searchResults && vm.searchResults.length > 0 ? true : false;
    }

    function changePage() {
        domService.scrollTop();
        getSearchResults();
    }

    function filtersCallback(filters) {
        searchFilters = filters;
        init();
    }

    function getSelectedMake() {
        return searchFilters && searchFilters.selectedMake && searchFilters.selectedMake.Title ? searchService.sanitizeForSearch(searchFilters.selectedMake.Title) : null;
    }

    function getSelectedModel() {
        return searchFilters && searchFilters.selectedModel && searchFilters.selectedModel.Title ? searchService.sanitizeForSearch(searchFilters.selectedModel.Title) : null;
    }

    function getSelectedAction() {
        return searchFilters && searchFilters.selectedAction && searchFilters.selectedAction.Title ? searchService.sanitizeForSearch(searchFilters.selectedAction.Title) : null;
    }

    function getSelectedShape() {
        return searchFilters && searchFilters.selectedShape && searchFilters.selectedShape.Title ? searchService.sanitizeForSearch(searchFilters.selectedShape.Title) : null;
    }

    function configureProgressBar() {
        vm.progressBar = ngProgress.createInstance();
        vm.progressBar.setParent(document.getElementById('header-layout'));
        vm.progressBar.setColor('#bf1e2e');
    }

	function startProgressIndicator() {
		$rootScope.DisplayPageLoadingSpinner = true;
	}

	function stopProgressIndicator() {
		$rootScope.DisplayPageLoadingSpinner = false;
	}
}