angular.module('boyds').directive('googleAutocomplete', [function () {
    return {
        scope: {
            callback: '='
        },
        link: function (scope, element, attrs, model) {
            scope.gPlace = new google.maps.places.Autocomplete(element[0]);

            google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
                var geoComponents = scope.gPlace.getPlace();
                var addressComponents = geoComponents.address_components;

                var address = {
                    streetAddress: getAddressComponent('street_number', addressComponents).short_name + " " + getAddressComponent('route', addressComponents).long_name,
                    city: getAddressComponent('locality', addressComponents).long_name,
                    state: {
                        Name: getAddressComponent('administrative_area_level_1', addressComponents).long_name,
                        IsoCode: getAddressComponent('administrative_area_level_1', addressComponents).short_name
                    },
                    postalCode: getAddressComponent('postal_code', addressComponents).long_name,
                    country: {
                        Name: getAddressComponent('country', addressComponents).long_name,
                        IsoCode: getAddressComponent('country', addressComponents).short_name
                    },
                };
                scope.callback(address);
            });
        }
    };

    function getAddressComponent(type, array) {
        for (var i = 0; i < array.length; i++)
            if (array[i].types.includes(type))
                return array[i];
        return null;
    }
}]);