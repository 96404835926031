
//:: HANDLES IMAGE SOURCE TO BACKGROUND IMAGE :://
var imageAsParentBackground = function () {
    var $img = $("img.set-as-background, .background-image-placeholder img");
    $img.each(function () {
        if (typeof $img !== "undefined") {
            var $target = $(this).closest(".container").parent();
            $target.addClass("has-background-image");
            var src = $(this).attr("src");
            $target.css("background-image", "url(" + src + ")");
            $(this).remove();
        }
    });
};

$(document).ready(function () {
    imageAsParentBackground();
});

