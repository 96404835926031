angular.module("boyds").directive('fancybox', ['config',function (config) {
    return {
        restrict: 'E',
        scope: {
            imageUrl: '@',
            title: '@'
        },
        templateUrl: config.templates.fancybox.url, 
        link: function (scope, element) {
            $(element).find('a').fancyboxPlus({
                'transitionIn' : 'fade',
                'transitionOut' : 'fade',
                'speedIn' : 200,
                'speedOut' : 200,
                'overlayShow' : true,
                'titlePosition': 'inside', 
                'margin':10
            });

            $(element).find('a').click(function(e){
                e.stopPropagation();
            });
        }
    };
}]); 