angular.module("boyds").service('productFiltersService', [ 'config', function (config) {
    function getProductFiltersObject() {
        return JSON.parse(sessionStorage.getItem(config.session.productFiltersObj))
    }

    function setProductFiltersObject(obj) {
        sessionStorage.setItem(config.session.productFiltersObj, JSON.stringify(obj));
    }

    function hasProductFiltersObj() {
        return sessionStorage.getItem(config.session.productFiltersObj) ? true : false;
    }

    return {
        getProductFiltersObject: getProductFiltersObject,
        setProductFiltersObject: setProductFiltersObject,
        hasProductFiltersObj: hasProductFiltersObj
    }
}]);