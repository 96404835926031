angular.module('boyds').controller('productFiltersController', productFiltersController);

productFiltersController.$inject = ['domService', 'service', '$rootScope', 'windowService', 'config', 'productFiltersService'];
function productFiltersController(domService, service, $rootScope, windowService, config, productFiltersService) {
    var vm = this;
    var productFromUrl = false;

    vm.makes = [];
    vm.models = [];
    vm.actions = [];

    vm.showProductFilters = true;
    vm.productUrlName = null;

    vm.isLoadingMakes = false;
    vm.isLoadingModels = false;
    vm.isLoadingActions = false;

    vm.isModelsDisabled = true;
    vm.isActionsDisabled = true;

    vm.focusMakes = false;
    vm.focusModels = false;
    vm.focusActions = false;

    vm.selectedMake = null;
    vm.selectedModel = null;
    vm.selectedAction = null;
    vm.preselectedProduct = null;

    vm.getMakes = getMakes;
    vm.selectMake = selectMake;
    vm.selectModel = selectModel;
    vm.selectAction = selectAction;
    vm.unfocused = unfocused;
    vm.resetProductFilters = resetProductFilters;
    vm.clearFiltersButton = clearFiltersButton;
    vm.loadGivenProduct = loadGivenProduct;
    vm.goToConfigurator = goToConfigurator;
    vm.isOnConfigurator = isOnConfigurator;

    init();

    return vm;

    function init() {
        redirectToPreviousPageAfterLogin();
        clearFilters();
        setProductUrlName();
        getMakes()
            .then(getPreselectedProduct)
            .then(loadGivenProduct)
            .then(function () {
                if (!isOnConfigurator())
                    setFiltersObj(null);
                if (vm.preselectFilter) { setFocus(); }
            });
    }

    function redirectToPreviousPageAfterLogin() {
        if (isOnHomePage() && hasValidRedirectCookie()) {
            var redirect = readCookie(config.cookie.loginRedirect);
            if (isUnwantedRedirect(redirect)) return;
            redirectPage(redirect)
        }
    }

    function isOnHomePage() {
        return (windowService.getPathName() == '/' || windowService.getPathName() == config.pages.routes.home);
    }

    function hasValidRedirectCookie() {
        return readCookie(config.cookie.loginRedirect) != null && getCookie(config.cookie.sfTokenId) != null;
    }

    function getCookie(key) {
        var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
        return keyValue ? keyValue[2] : null;
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    function isUnwantedRedirect(redirect) {
        return isRedirectPage(redirect, config.pages.routes.userRegistration) 
            || isRedirectPage(redirect, config.pages.routes.home);
    }

    function isRedirectPage(redirect, page) {
        return redirect.indexOf(page) != -1;
    }

    function redirectPage(redirect) {
        eraseCookie(config.cookie.loginRedirect);
        windowService.setLocation(redirect);
    }

    function eraseCookie(name) {
        createCookie(name, "", -1);
    }

    //Might be able to cut some of this out
    function createCookie(name, value, days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
        }
        else var expires = "";
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    function getPreselectedProduct() {
        if ((isOnProductDetail() || isOnConfigurator()) && vm.productUrlName)
            return getGunstockClassificationIds();
        else if (isOnConfigurator() && productFiltersService.hasProductFiltersObj())
            vm.preselectedProduct = productFiltersService.getProductFiltersObject();
        return;
    }

    function loadGivenProduct() {
        if (vm.preselectedProduct)
            return selectMake(getPreselectedMake())
                .then(function () { vm.selectedMake = getPreselectedMake(); })
                .then(function () { return selectModel(getPreselectedModel()); })
                .then(function () { vm.selectedModel = getPreselectedModel(); })
                .then(function () { return selectAction(getPreselectedAction()); })
                .then(function () { vm.selectedAction = getPreselectedAction(); });
        else {
            $rootScope.$broadcast(config.events.productFiltersCallback);
            return;
        }
    }

    function getGunstockClassificationIds() {
        return service.getGunstockClassificationIds(vm.productUrlName).then(function (response) {
            vm.preselectedProduct = response.data;
            productFromUrl = true;
        });
    }

    function setProductUrlName() {
        var brokenPath = windowService.getPathName().split("/");
        if (brokenPath.length <= 2)
            return;
        var productUrlName = brokenPath[brokenPath.length - 1];
        if (productUrlName !== config.pages.productConfigurator) vm.productUrlName = productUrlName;
    }

    function callback() {
        if (isOnConfigurator()) {
            if (productFromUrl)
                setFiltersObj(getCallbackObject());
            else
                setFiltersObj(getBaseCallbackObject());
            $rootScope.$broadcast(config.events.productFiltersCallback);
        }

        productFromUrl = false;
    }

    function goToConfigurator() {
        if (allFiltersAreSelected()) {
            setFiltersObj(getCallbackObject());
            routeToConfigurator();
        }
    }

    function setFiltersObj(obj) {
        productFiltersService.setProductFiltersObject(obj);
    }

    function routeToConfigurator() {
        windowService.setHref(config.pages.productConfigurator);
    }

    function isOnConfigurator() {
        return windowService.getHref().indexOf(config.pages.productConfigurator) > -1;
    }

    function isOnProductDetail() {
        return windowService.getHref().indexOf(config.pages.productDetail) > -1;
    }

    function allFiltersAreSelected() {
        return vm.selectedMake && vm.selectedModel && vm.selectedAction;
    }

    function selectMake(make) {
        vm.selectedMake = make;
        if (!make || make.Id === undefined) return;
        vm.models = vm.actions = [];
        return getModels()
            .then(setFocus);
    }

    function selectModel(model) {
        vm.selectedModel = model;
        if (!model || model.Id === undefined) return;
        return getActions()
            .then(setFocus);
    }

    function selectAction(action) {
        vm.selectedAction = action;
        if (!action || action.Id === undefined) return;
        callback();
        setFocus();
    }

    function getMakes() {
        vm.isLoadingMakes = true;
        return getAllMakes();
    }

    function getAllMakes() {
        return service.getMakes().then(function (response) {
            vm.makes = response.data;
            vm.isLoadingMakes = false;
        });
    }

    function getModels() {
        vm.isLoadingModels = true;
        return getAllModels();
    }

    function getAllModels() {
        return service.getModels(vm.selectedMake.Id).then(function (response) {
            vm.models = response.data;
            setupModelsDropdown();
            vm.isLoadingModels = false;
        });
    }

    function getActions() {
        vm.isLoadingActions = true;
        return getAllActions();
    }

    function getAllActions() {
        return service.getActions(vm.selectedModel.Id).then(function (response) {
            vm.actions = response.data;
            setupActionsDropdown();
            vm.isLoadingActions = false;
        });
    }

    function setupMakesDropdown() {
        vm.selectedMake = null;
        vm.selectedModel = null;
        vm.selectedAction = null;
        vm.isModelsDisabled = true;
        vm.isActionsDisabled = true;
    }

    function setupModelsDropdown() {
        vm.selectedModel = null;
        vm.selectedAction = null;
        vm.isActionsDisabled = true;
        vm.isModelsDisabled = false;
    }

    function setupActionsDropdown() {
        vm.selectedAction = null;
        vm.isActionsDisabled = false;
    }

    function clearFilters() {
        clearAllSelections();
        clearDropdownOptions();
        resetDropdownsToDisabled();
    }

    function clearFiltersButton() {
        resetProductFilters();
    }

    function resetProductFilters() {
        clearFilters();
        return getMakes().then(function () { if (vm.preselectFilter) setFocus(); });
    }

    function clearAllSelections() {
        vm.selectedMake = null;
        vm.selectedModel = null;
        vm.selectedAction = null;
    }

    function clearDropdownOptions() {
        vm.makes = [];
        vm.models = [];
        vm.actions = [];
    }

    function resetDropdownsToDisabled() {
        vm.isModelsDisabled = true;
        vm.isActionsDisabled = true;
    }

    function setFocus() {
        resetFocus();
        if (vm.selectedMake === null) vm.focusMakes = true;
        else if (vm.selectedModel === null) vm.focusModels = true;
        else if (vm.selectedAction === null) vm.focusActions = true;
    }

    function resetFocus() {
        vm.focusMakes = false;
        vm.focusModels = false;
        vm.focusActions = false;
    }

    function unfocused(event) {
        domService.attr(event.target, 'size', 0);
        domService.removeClass(event.target, 'floating-select');
    }

    function getBaseCallbackObject() {
        return {
            selectedMake: vm.selectedMake,
            selectedModel: vm.selectedModel,
            selectedAction: vm.selectedAction,
            MakeId: vm.selectedMake.Id,
            ModelId: vm.selectedModel.Id,
            ActionId: vm.selectedAction.Id,
            ShapeId: null
        };
    }

    function getCallbackObject() {
        var base = getBaseCallbackObject();
        base.ShapeId = vm.preselectedProduct && vm.preselectedProduct.ShapeId ? vm.preselectedProduct.ShapeId : null
        return base;
    }

    function getPreselectedMake() {
        for (var i = 0; i < vm.makes.length; i++)
            if (vm.makes[i].Id === vm.preselectedProduct.MakeId)
                return vm.makes[i];
    }

    function getPreselectedModel() {
        for (var i = 0; i < vm.models.length; i++)
            if (vm.models[i].Id === vm.preselectedProduct.ModelId)
                return vm.models[i];
    }

    function getPreselectedAction() {
        for (var i = 0; i < vm.actions.length; i++)
            if (vm.actions[i].Id === vm.preselectedProduct.ActionId)
                return vm.actions[i];
    }
}