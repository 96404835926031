angular.module("boyds").controller('cookieConsentController', cookieConsentController).animation('.slide', slideAnimation);
cookieConsentController.$inject = ['cookieConsentService', '$scope'];

function cookieConsentController(service, $scope) {
    var vm = this;

    vm.displayCookieConsent = !service.isConsentStatusKnown()
    vm.onAcceptButtonClick = onAcceptButtonClick;
    vm.onDeclineButtonClick = onDeclineButtonClick;

    function onAcceptButtonClick() {
        vm.displayCookieConsent = false
        service.grantConsent()
    }

    function onDeclineButtonClick() {
        vm.displayCookieConsent = false
        service.denyConsent()
    }
}

function slideAnimation() {
    var NG_HIDE_CLASS = 'ng-hide';
    return {
        beforeAddClass: function (element, className, done) {
            if (className === NG_HIDE_CLASS) {
                element.slideUp(done);
            }
        },
        removeClass: function (element, className, done) {
            if (className === NG_HIDE_CLASS) {
                element.hide().slideDown(done);
            }
        }
    }
}