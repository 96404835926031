angular.module("boyds").factory('cookieConsentService', ['$http', 'config', function ($http, config) {
    var consentCookieName = 'boyds_cookie_consent'
    function grantConsent() {
        createCookie(consentCookieName, 'granted', 30, '/', window.location.hostname.replace('www', ''))
        //console.log(getCookie(consentCookieName))
        fireGTMEvent('boyds_cookie_consent_updated');
    }
    function denyConsent() {
        createCookie(consentCookieName, 'denied', 30, '/', window.location.hostname.replace('www', ''))
        fireGTMEvent('boyds_cookie_consent_updated');
        getCookiesForRemoval().then(function (cookies) {
            cookies.forEach(function (cookie) {
                expireCookie(cookie.Name)
            })
        })
    }
    function isConsentStatusKnown() { 
        return !!getCookie(consentCookieName)
    }
    function getCookiesForRemoval(page, pageSize) {
        return $http({
            url: config.cookieConsentService.getCookiesForRemoval.url,// + "?page=" + (page || 0) + "&pageSize=" + (pageSize || 50),
            method: 'GET'
        }).then(function successCallback(response) {
            if (response.status === 200)
                return response.data.value;
        });
    }
    function expireCookie(name) {
        console.log('expiring: ' + name)
        //console.log('value: ' + getCookie(name))
        var cookieValue = getCookie(name)
        if (cookieValue) {
            createCookie(name, "", -1, '/', '.' + window.location.hostname);
            createCookie(name, "", -1, '/', window.location.hostname);
        }
        //console.log('value: ' + getCookie(name))
    }
    function getCookie(c_name) {
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(c_name + "=");
            if (c_start != -1) {
                c_start = c_start + c_name.length + 1;
                var c_end = document.cookie.indexOf(";", c_start);
                if (c_end == -1) {
                    c_end = document.cookie.length;
                }
                return unescape(document.cookie.substring(c_start, c_end));
            }
        }
        return "";
    }
    function createCookie(name, value, expires, path, domain) {
        var cookie = name + "=" + escape(value) + ";";

        if (expires) {
            // If it's a date
            if (expires instanceof Date) {
                // If it isn't a valid date
                if (isNaN(expires.getTime()))
                    expires = new Date();
            }
            else {
                expires = new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24);
            }

            cookie += "expires=" + expires.toGMTString() + ";";
        }

        if (path)
            cookie += "path=" + path + ";";
        if (domain)
            cookie += "domain=" + domain + ";";

        document.cookie = cookie;
    }
    function fireGTMEvent(eventName) {
        window.dataLayer && window.dataLayer.push({
            'event': eventName
        })
    }
    return {
        grantConsent: grantConsent,
        denyConsent: denyConsent,
        isConsentStatusKnown: isConsentStatusKnown,
    }
}]);