angular.module("boyds").controller('operationInstructionsController', operationInstructionsController);
operationInstructionsController.$inject = ['$rootScope', '$scope', '$sce', '$timeout', 'service'];
function operationInstructionsController($rootScope, $scope, $sce, $timeout, service) {
    var vm = this;
    vm.workstationDropdownData = {
        getSelectedWorkstationCacheLabel: () => 'selectedWorkstationGuid',

        workstations: null,
        selectedWorkstationDefaultOptionValue: '',
        selectedWorkstationGuid: '',
        workStationSelected: workStationSelected
    };
    vm.barcodeData = {
        searchTerm: ''
    };
    vm.operationInstructions = {
        data: null
    };
    vm.constraints = window.constraints = {
        audio: false,
        video: true
    };
    vm.requestHelpData = {
        helpType: '',
        additionalInfo: '',
        requestFeedback: '',
    };
    vm.error = null;
    vm.onScanButtonClick = onScanButtonClick;
    vm.onClickRequestHelp = onClickRequestHelp;
    vm.onSendHelpRequest = onSendHelpRequest;
    vm.trust = trust;
    init();
    return vm;

    function init() {
        preventFormSubmit();
        getWorkstations();
        performSearchWhenSearchTermChanges();
        setupBarcodeScanning();
    }

    function setupBarcodeScanning() {
        $(document).on({
            normaldeviceinput: function (e, barCode) {
                setSearchTerm(vm.barcodeData.searchTerm + barCode); // Append normal input, like single keystrokes
            },
            altdeviceinput: function (e, barCode) {
                setSearchTerm(barCode); // Replace the entire value with alternate input, like a barcode scanner
            }
        });
        $('.po-search').on({
            keypress: _onKeypress
        });
    }

    function getWorkstations() {
        displayProgressIndicator(true);
        return service.getWorkstations().then(function (response) {
            vm.workstationDropdownData.workstations = response;
            vm.workstationDropdownData.selectedWorkstationGuid = loadCachedSelection();
            focusInput();
            displayProgressIndicator(false);
        });
    }

    function loadCachedSelection() {
        return localStorage[vm.workstationDropdownData.getSelectedWorkstationCacheLabel()] ?? vm.workstationDropdownData.selectedWorkstationDefaultOptionValue;
    }

    function focusInput() {
        $('.po-search').focus();
    }

    function workStationSelected() {
        localStorage.setItem(vm.workstationDropdownData.getSelectedWorkstationCacheLabel(), vm.workstationDropdownData.selectedWorkstationGuid);
        focusInput();
    }

    function displayProgressIndicator(display) {
        $rootScope.DisplayPageLoadingSpinner = display;
    }

    function performSearchWhenSearchTermChanges() {
        $scope.$watch('vm.workstationDropdownData.selectedWorkstationGuid', searchForOperationInstructions);
        $scope.$watch('vm.barcodeData.searchTerm', searchForOperationInstructions);
    }

    function onScanButtonClick() {
        var isScanning = $('.camera-preview video').length;

        if (isScanning)
            stopWebcam();
        else
            startWebcam();
    }

    function searchForOperationInstructions() {
        setErrorMessage(null);
        vm.operationInstructions.data = null;
        if (!workstationIsSelected())
            return;
        if (!barcodeIsEntered())
            return;

        displayProgressIndicator(true);
        service.getWorkstationInstructions(vm.workstationDropdownData.selectedWorkstationGuid, vm.barcodeData.searchTerm).then(function (response) {
            vm.operationInstructions.data = response;
            console.log('%c Operation Instructions Response', 'color: green; text-decoration: underline;')
            console.log(response);
        }).catch(function (errorMessage) {
            setErrorMessage(errorMessage);
        }).finally(() => {
            displayProgressIndicator(false);
        });
    }

    function workstationIsSelected() {
        return vm.workstationDropdownData.selectedWorkstationGuid !== '';
    }

    function barcodeIsEntered() {
        return vm.barcodeData.searchTerm.length > 0;
    }

    function startWebcam() {
        setSearchTerm('');
        setErrorMessage(null);
        navigator.mediaDevices.getUserMedia(vm.constraints)
            .then(handleWebcamStream)
            .catch(handleWebcamError);
    }

    function handleWebcamStream(stream) {
        const video = document.querySelector('.camera-preview');
        const videoTracks = stream.getVideoTracks();
        console.log(`Using video device: ${videoTracks[0].label}`);

        Quagga.init({
            inputStream: {
                name: "Live",
                type: "LiveStream",
                target: video
            },
            decoder: {
                readers: [
                    "code_128_reader",
                    "ean_reader",
                    "ean_8_reader",
                    "code_39_reader",
                    "code_39_vin_reader",
                    "codabar_reader",
                    "upc_reader",
                    "upc_e_reader",
                    "i2of5_reader"
                ]
            }
        }, function (e) {
            if (e) {
                handleWebcamError(e);
                return;
            }
            console.log("Initialization finished. Ready to start");
            Quagga.start();
            updateButtonState(true);
        });
        Quagga.onDetected(onBarcodeDetected);
    }

    function onBarcodeDetected(result) {
        var barcode = result.codeResult.code;
        const regex = /^PO\d+$/; //Matches any string that starts with PO followed by any amount of numbers
        if (!regex.exec(barcode)) {
            alert('barcode')
            return;
        }

        stopWebcam();
        setSearchTerm(barcode);
        alert("Barcode detected and processed : [" + barcode + "]");
    }

    function handleWebcamError(e) {
        stopWebcam();
        if (e.name === 'NotFoundError') {
            setErrorMessage('A camera was not found')
        }
        else if (e.name === 'NotAllowedError') {
            setErrorMessage('Permissions have not been granted to use your camera');
        }
        alert(`getUserMedia error: ${e.name}`)
    }

    function stopWebcam() {
        Quagga.stop();
        $('.camera-preview').children(':not("button")').remove();
        updateButtonState();
    }

    function updateButtonState(isStartingWebcam) {
        var btn = $('.camera-preview button');
        var previewClass = 'preview-position';
        if (isStartingWebcam)
            btn.addClass(previewClass);
        else
            btn.removeClass(previewClass);
    }

    function setSearchTerm(msg) {
        vm.barcodeData.searchTerm = msg;
        if (!$scope.$$phase)
            $scope.$apply();
    }

    function onClickRequestHelp() {
        let modal = getHelpRequestModal();
        let isCurrentlyHidden = isRequestHelpCurrentlyHidden();
        let button = $('.operator-help > button');
        setRequestFeedback('');
        swapRequestHelpIcon(button);
        if (isCurrentlyHidden) {
            modal.removeClass('hide');
        }
        else {
            modal.addClass('hide');
        }
    }

    function swapRequestHelpIcon(button) {
        let classList = button.attr('class').split(/\s+/);
        let mainIconClass = classList.find(clazz => clazz.includes('fa-'));
        let alternateIconClass = button.data('alternate-class');
        button.removeClass(mainIconClass);
        button.addClass(alternateIconClass);
        button.data('alternate-class', mainIconClass);
    }

    function onSendHelpRequest() {
        let workstation = getSelectedWorkstation();
        let workstationId = getSelectedWorkstationId();
        let purchaseOrderNumber = vm.barcodeData.searchTerm;
        let helpType = vm.requestHelpData.helpType;
        let additionalInfo = vm.requestHelpData.additionalInfo;
        displayProgressIndicator(true);
        service.requestHelp(workstation, workstationId, purchaseOrderNumber, helpType, additionalInfo)
            .then(response => {
                vm.requestHelpData.helpType = '';
                vm.requestHelpData.additionalInfo = '';
                setRequestFeedback('Your request has been submitted!');
                $timeout(() => {
                    if (!isRequestHelpCurrentlyHidden()) {
                        onClickRequestHelp();
                    }
                }, 3000);
            })
            .catch(msg => {
                setRequestFeedback('There was a problem sending your request.');
                $timeout(() => {
                    setRequestFeedback('');
                }, 3000);
            })
            .finally(() => {
                displayProgressIndicator(false);
            });
    }

    function setRequestFeedback(msg) {
        vm.requestHelpData.requestFeedback = msg;
    }

    function isRequestHelpCurrentlyHidden() {
        let modal = getHelpRequestModal();
        return modal.hasClass('hide');
    }

    function getHelpRequestModal() {
        return $('.operator-help section');
    }

    function getSelectedWorkstation() {
        return vm.workstationDropdownData.workstations
            ?.find(workstation => workstation.Guid == vm.workstationDropdownData.selectedWorkstationGuid)
            ?.DisplayNumber;
    }

    function getSelectedWorkstationId() {
        return vm.workstationDropdownData.workstations
            ?.find(workstation => workstation.Guid == vm.workstationDropdownData.selectedWorkstationGuid)
            ?.Guid;
    }

    function setErrorMessage(msg, e) {
        vm.error = msg;
    }

    function preventFormSubmit() {
        angular.element(document).find("form").on("submit", function (e) { e.preventDefault(); });
    }

    function trust(html) {
        return $sce.trustAsHtml(html);
    }
}

var _timeoutHandler = 0,
    _inputString = '',
    _onKeypress = function (e) {
        e.preventDefault();
        if (_timeoutHandler) {
            clearTimeout(_timeoutHandler);
        }
        _inputString += e.key;

        _timeoutHandler = setTimeout(function () {
            if (_inputString.length <= 3) {
                $(e.target).trigger('normaldeviceinput', _inputString);
            } else {
                $(e.target).trigger('altdeviceinput', _inputString);
            }
            _inputString = '';
        }, 20);
    };
